import styled from '@emotion/styled';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Check as CheckIcon,
} from '@mui/icons-material';

import { MouseEvent, useEffect, useState } from 'react';
import CheckpointAccordion, {
  AudioData,
} from '../components/customAccordion/CheckpointAccordion';
import useAuth from '../../auth/useAuth';
import axios from 'axios';
import toast from 'react-hot-toast';

const StyledWrapper = styled('div')({
  marginTop: '2rem',

  'h3.MuiTypography-root': {
    fontSize: '1.25rem',
  },

  '& .MuiSelect-select': {
    backgroundColor: 'white',
  },

  '& .evaluation-option': {
    backgroundColor: 'white',
    borderRadius: '7px',
    padding: '5px 16px',
  },

  '& .centered-progress': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

type AudioFileInfo = {
  [key: string]: AudioData[];
};

type EvalData = {
  running: {
    running_id: string;
    running_name: string;
    selected_steps: number;
  };
  evaluation_artifacts: {
    evaluation_artifacts: Array<{
      step: {
        steps: number;
        step_type: string;
      };
      audio_set: {
        audio_files_info: AudioFileInfo;
      };
      checkpoint: object;
    }>;
  };
};

const EvaluationPage = () => {
  const [evalData, setEvalData] = useState<EvalData>();
  const [selectedSteps, setSelectedSteps] = useState<number>(0);

  const { id } = useParams();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const tunerBaseURL = process.env.REACT_APP_TUNER_API_HOST;
  const location = useLocation();
  const isRtvc = location.state as boolean;

  useEffect(() => {
    getEvaluation();
  }, []);

  const getEvaluation = async () => {
    try {
      const { data } = await axios.get(
        tunerBaseURL + '/v1/evaluation/runnings/' + id
      );

      setEvalData(data);
    } catch (err) {
      toast.error(
        formatMessage({
          id: 'tuner.msg.error-evaluation',
          defaultMessage: 'Failed to retrieve the evaluation information.',
        })
      );
    }
  };

  useEffect(() => {
    if (!evalData) return;

    const { running, evaluation_artifacts } = evalData;

    if (running.selected_steps === 0)
      setSelectedSteps(evaluation_artifacts.evaluation_artifacts[0].step.steps);
    else setSelectedSteps(running.selected_steps);
  }, [evalData]);

  const onStepChange = (event: SelectChangeEvent<number>) => {
    setSelectedSteps(Number(event.target.value));
  };

  const onSelectClick = async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    try {
      await axios.post(
        tunerBaseURL +
          '/v1/evaluation/runnings/' +
          id +
          '/select/' +
          selectedSteps
      );

      navigate('/tuner/history');
    } catch (err) {
      toast.error(
        formatMessage({
          id: 'tuner.msg.error-select-step',
          defaultMessage: 'Failed to select steps.',
        })
      );
    }
  };

  const artifacts = evalData?.evaluation_artifacts.evaluation_artifacts;

  return (
    <StyledWrapper>
      <section>
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            spacing={10}
            display={'inline-flex'}
          >
            <Typography variant="h3">
              <FormattedMessage
                id="tuner.menu.evaluation"
                defaultMessage="Evaluation"
              />
            </Typography>
          </Stack>
          <Stack
            spacing={5}
            direction="row"
            alignItems="center"
            alignSelf="center"
          >
            <Button
              variant="outlined"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIcon />}
              sx={{
                color: '#287578c2',
                border: '1px solid rgb(41 117 120 / 54%)',
                fontSize: '13px',
              }}
            >
              Back
            </Button>
          </Stack>
        </Stack>
      </section>
      {evalData && (
        <>
          <section className="evaluation-option">
            <Stack
              alignItems="center"
              justifyContent="space-between"
              direction="row"
            >
              <Stack
                alignItems="center"
                justifyContent="space-between"
                direction="row"
                spacing={10}
                display={'inline-flex'}
              >
                <Typography variant="body1" component="span">
                  <FormattedMessage
                    id="tuner.evaluation.model"
                    defaultMessage="Model"
                  />
                </Typography>
                <Typography variant="body2" component="span">
                  {evalData.running.running_name}
                </Typography>
              </Stack>
              <Stack
                spacing={5}
                direction="row"
                alignItems="center"
                alignSelf="center"
              >
                <Typography variant="body1" component="span">
                  <FormattedMessage
                    id="tuner.evaluation.checkpoint"
                    defaultMessage="체크포인트"
                  />
                </Typography>
                <FormControl
                  sx={{
                    minWidth: '220px',
                    width: '30%',
                  }}
                >
                  {selectedSteps && (
                    <Select
                      value={selectedSteps}
                      onChange={onStepChange}
                      sx={{ fontSize: '14px' }}
                    >
                      {artifacts?.map((checkpoint, index) => (
                        <MenuItem key={index} value={checkpoint.step.steps}>
                          {`${checkpoint.step.steps}-${
                            checkpoint.step.step_type
                          }${
                            evalData.running.selected_steps ===
                            checkpoint.step.steps
                              ? ' (selected)'
                              : ''
                          }`}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
                {!isRtvc && (
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<CheckIcon />}
                    onClick={onSelectClick}
                    disabled={selectedSteps === evalData.running.selected_steps}
                    sx={{
                      background: '#287578',
                      '&:hover': {
                        background: '#206366',
                      },
                    }}
                  >
                    <FormattedMessage
                      id="tuner.evaluation.select"
                      defaultMessage="Select"
                    />
                  </Button>
                )}
              </Stack>
            </Stack>
          </section>
          <section>
            {artifacts
              ?.filter((audioSet) => audioSet.step.steps === selectedSteps)
              ?.map((filteredAudioSet) =>
                Object.keys(filteredAudioSet.audio_set.audio_files_info).map(
                  (key, keyIndex) => (
                    <CheckpointAccordion
                      key={keyIndex}
                      step={selectedSteps}
                      audioSet={key}
                      audioData={
                        filteredAudioSet.audio_set.audio_files_info[key]
                      }
                      showAccordionName={!isRtvc}
                    />
                  )
                )
              )}
          </section>
        </>
      )}
      <section className="centered-progress">
        {!evalData && <CircularProgress />}
      </section>
    </StyledWrapper>
  );
};

export default EvaluationPage;
