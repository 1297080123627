import { ReactNode, useState } from 'react';
import cuid from 'cuid';
import cn from 'classnames';
import { FormControlLabel, Slider, Tooltip } from '@mui/material';

import ToggleSwitch from '../ToggleSwitch';

interface Props<T> {
  name: keyof T;
  label: string;
  tooltip?: string;
  defaultValue: number;
  value?: number;
  minValue?: number;
  maxValue?: number;
  minLabel?: string;
  maxLabel?: string;
  step?: number;
  isToggleable?: boolean;
  defaultToggle?: boolean;
  valueFormatter?: (v: number) => string;
  onChange: (name: keyof T, value?: number) => void;
  onToggle?: (name: keyof T, value?: number) => void;
}

const SliderParameter = <T,>({
  name,
  label,
  tooltip,
  defaultValue,
  value,
  isToggleable,
  defaultToggle,
  minValue,
  maxValue,
  minLabel,
  maxLabel,
  step = 1,
  valueFormatter,
  onChange,
  onToggle,
}: Props<T>) => {
  const [isToggledOn, setIsToggledOn] = useState(
    (isToggleable && defaultToggle) || !isToggleable
  );
  const [id] = useState(cuid());

  const onValueChange = (_: Event, v: number | number[]) => {
    if (Array.isArray(v)) return;

    if (v !== value) {
      onChange(name, isToggledOn ? v : undefined);
    }
  };

  const onToggleCheckboxClick = () => {
    const v = !isToggledOn;

    if (onToggle) onToggle(name, v ? defaultValue : undefined);

    setIsToggledOn(v);
  };

  const v = value ?? defaultValue;

  let child: ReactNode = null;

  if (minValue !== undefined && maxValue !== undefined) {
    const marks = [
      {
        value: minValue,
        label: `${minLabel ? minLabel : 'min'}(${minValue.toString()})`,
      },
      {
        value: maxValue,
        label: `${maxLabel ? maxLabel : 'max'}(${maxValue.toString()})`,
      },
    ];

    child = (
      <Slider
        defaultValue={defaultValue}
        value={v}
        min={minValue}
        max={maxValue}
        step={step}
        marks={marks}
        onChange={onValueChange}
      />
    );
  }

  return (
    <div className={cn('parameter')}>
      {isToggleable ? (
        <>
          <div className="inner">
            <Tooltip
              title={tooltip ? tooltip : ''}
              arrow
              placement="bottom-start"
            >
              <FormControlLabel
                control={
                  <ToggleSwitch
                    id={`input-${id}`}
                    checked={!!isToggledOn}
                    onChange={onToggleCheckboxClick}
                  />
                }
                label={label}
              />
            </Tooltip>
            {isToggledOn && (
              <span className={cn('value')}>
                {valueFormatter ? valueFormatter(v) : v}
              </span>
            )}
          </div>
          {isToggledOn && <div className="slider-container">{child}</div>}
        </>
      ) : (
        <>
          <div className="inner">
            <Tooltip
              title={tooltip ? tooltip : ''}
              arrow
              placement="bottom-start"
            >
              <label>{label}</label>
            </Tooltip>
            <span className={cn('value')}>
              {valueFormatter ? valueFormatter(v) : v}
            </span>
          </div>
          <div className="slider-container">{child}</div>
        </>
      )}
    </div>
  );
};

export default SliderParameter;
