import {
  createContext,
  useState,
  PropsWithChildren,
  useMemo,
  useCallback,
} from 'react';

type AudioContextType = {
  currentAudioIndices: Record<string, number>;
  setCurrentAudioIndices: (newIndices: Record<string, number>) => void;
  stopAudio: () => Promise<void>;
  playNewAudio: (newIndices: Record<string, number>) => void;
};

const EvaluationAudioContext = createContext<AudioContextType>({
  currentAudioIndices: {},
  setCurrentAudioIndices: () => {},
  stopAudio: async () => {},
  playNewAudio: () => {},
});

const EvaluationAudioProvider = ({ children }: PropsWithChildren) => {
  const [currentAudioIndices, setCurrentAudioIndices] = useState<
    Record<string, number>
  >({});

  // Stop any currently playing audio
  const stopAudio = () => {
    return new Promise<void>((resolve) => {
      setCurrentAudioIndices({});
      resolve();
    });
  };

  const playNewAudio = useCallback(
    (newIndices: Record<string, number>) => {
      // If no audio is currently playing
      if (Object.keys(currentAudioIndices).length === 0) {
        setCurrentAudioIndices(newIndices);
      }
      // If audio is playing, stop the current audio and play the new one
      else {
        stopAudio().then(() => {
          setCurrentAudioIndices(newIndices);
        });
      }
    },
    [currentAudioIndices, stopAudio]
  );

  const contextValue = useMemo(() => {
    return {
      currentAudioIndices,
      playNewAudio,
      stopAudio,
    } as AudioContextType;
  }, [currentAudioIndices, playNewAudio, stopAudio]);

  return (
    <EvaluationAudioContext.Provider value={contextValue}>
      {children}
    </EvaluationAudioContext.Provider>
  );
};

export { EvaluationAudioProvider, EvaluationAudioContext };
