import React, {
  useRef,
  PropsWithChildren,
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useCallback,
} from 'react';
import { RowHeightChange, RowPropertiesMap } from '../types';

import Row from './Row';

interface Props {
  height?: string;
  className?: string;
}

const AreaGrid = ({
  height = '100%',
  className = '',
  children,
}: PropsWithChildren<Props>) => {
  const areas = useRef<string[]>([]);
  const grid = useRef<HTMLDivElement>(null);
  const rowHeights = useRef<string[]>([]);
  const rowPropMap = useRef<RowPropertiesMap>({});

  // Append each row to the areas
  const onRowAreasAdded = useCallback(
    (rowAreas: string[]) => {
      areas.current.push(`'${rowAreas.join(' ')}'`);
    },
    [areas]
  );

  // Resizes row heights - pointermove
  const onRowHeightChange = useCallback(
    ({ index, delta = 0 }: RowHeightChange) => {
      const heights = [...rowHeights.current];
      const prop = rowPropMap.current[index];
      const op = prop.splitterAt === 'bottom' ? '+' : '-';
      const size = `calc(${heights[index]} ${op} ${delta}px)`;

      if (prop.minSize && prop.maxSize)
        heights[index] = `clamp(${prop.minSize}, ${size}, ${prop.maxSize})`;
      else if (prop.minSize) heights[index] = `max(${prop.minSize}, ${size})`;
      else if (prop.maxSize) heights[index] = `min(${prop.maxSize}, ${size})`;
      else heights[index] = size;

      if (grid.current) grid.current.style.gridTemplateRows = heights.join(' ');
    },
    [grid]
  );

  // Resizes row heights = pointerup
  const onRowHeightUpdate = useCallback(
    ({ index, delta }: RowHeightChange) => {
      onRowHeightChange({ index, delta });

      // Get the first column of the row
      const cols = areas.current[index].replace(/'/g, '').split(' ');
      const areaId = `area-${cols[0]}`;
      const area = document.getElementById(areaId);

      if (area) {
        rowHeights.current[index] = `${area.offsetHeight}px`;

        if (grid.current)
          grid.current.style.gridTemplateRows = rowHeights.current.join(' ');
      }
    },
    [grid, onRowHeightChange]
  );

  // Validate and process the rows
  const rows = Children.map(children, (c, index) => {
    if (isValidElement(c) && c.type === Row) {
      rowHeights.current.push(c.props.size ?? 'auto');

      rowPropMap.current[index] = {
        size: c.props.size,
        minSize: c.props.minSize,
        maxSize: c.props.maxSize,
        splitterAt: c.props.splitterAt,
      };

      return cloneElement<any>(c, {
        index,
        grid,
        onRowHeightChange,
        onRowHeightUpdate,
        onRowAreasAdded,
      });
    } else {
      throw new Error(
        'AreaGrid can only contain `Row`s as its direct children.'
      );
    }
  });

  // Mininum of one row is required
  if (!rows) {
    throw new Error('AreaGrid must have at least one `Row`.');
  }

  // Set the row heights
  useEffect(() => {
    if (grid.current) {
      const totalHeights = rowHeights.current.reduce(
        (acc, h) => acc + (h !== 'auto' ? parseInt(h, 10) : 0),
        0
      );

      rowHeights.current.forEach((h, i) => {
        if (h === 'auto')
          rowHeights.current[i] = `calc(${height} - ${totalHeights}px)`;
      });

      grid.current.style.gridTemplateRows = rowHeights.current.join(' ');
    }
  }, [height, rowHeights]);

  // Set the grid areas
  useEffect(() => {
    if (grid.current)
      grid.current.style.gridTemplateAreas = areas.current.join(' ');
  }, [areas]);

  return (
    <div ref={grid} className={`area-grid ${className}`}>
      {rows}
    </div>
  );
};

export default AreaGrid;
