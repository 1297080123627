import React, {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  AreaPropertiesMap,
  AreaWidthChange,
  RowHeightChange,
  SplitterProperties,
} from '../types';

import Area from './Area';

interface Props {
  grid?: RefObject<HTMLDivElement>;
  index?: number;
  onAreaChange?: (name: string, size: string) => void;
  onRowAreasAdded?: (areas: string[]) => void;
  onRowHeightChange?: (change: RowHeightChange) => void;
  onRowHeightUpdate?: (change: RowHeightChange) => void;
  size?: string;
  splitterAt?: 'top' | 'bottom';
}

const Row = ({
  grid,
  index,
  size,
  splitterAt,
  onRowAreasAdded,
  onRowHeightChange,
  onRowHeightUpdate,
  children,
}: PropsWithChildren<Props>) => {
  const areas = useRef<string[]>([]);
  const areaWidths = useRef<string[]>([]);
  const areaPropMap = useRef<AreaPropertiesMap>({});

  // Resizes area widths - pointermove
  const onAreaWidthChange = useCallback(
    ({ index, delta = 0 }: AreaWidthChange) => {
      const widths = [...areaWidths.current];
      const prop = areaPropMap.current[index];
      const op = prop.splitterAt === 'right' ? '+' : '-';
      const size = `calc(${widths[index]} ${op} ${delta}px)`;

      // check minSize and maxSize
      if (prop.minSize && prop.maxSize)
        widths[index] = `clamp(${prop.minSize}, ${size}, ${prop.maxSize})`;
      else if (prop.minSize) widths[index] = `max(${prop.minSize}, ${size})`;
      else if (prop.maxSize) widths[index] = `min(${prop.maxSize}, ${size})`;
      else widths[index] = size;

      if (grid && grid.current)
        grid.current.style.gridTemplateColumns = widths.join(' ');
    },
    [grid]
  );

  // Add areas in this row
  useEffect(() => {
    if (onRowAreasAdded) onRowAreasAdded(areas.current);

    if (grid && grid.current) {
      const cols = (grid.current.style.gridTemplateColumns ?? '').split(' ');

      // Initialize column widths only when there are more columns than before
      if (areas.current.length > cols.length) onAreaWidthChange({ index: 0 });
    }
  }, [areas, onRowAreasAdded, grid, onAreaWidthChange]);

  // Resizes area widths - pointerup
  const onAreaWidthUpdate = useCallback(
    ({ index, delta }: AreaWidthChange) => {
      onAreaWidthChange({ index, delta });

      const areaId = `area-${areas.current[index]}`;
      const area = document.getElementById(areaId);

      if (area) {
        areaWidths.current[index] = `${area.offsetWidth}px`;

        if (grid && grid.current)
          grid.current.style.gridTemplateColumns = areaWidths.current.join(' ');
      }
    },
    [grid, onAreaWidthChange]
  );

  areas.current = [];
  areaWidths.current = [];

  const cols = Children.map(children, (c, colIndex) => {
    if (isValidElement(c) && c.type === Area) {
      const area = c.props.name;
      const span = c.props.span ?? 1;

      for (let i = 0; i < span; i++) {
        areas.current.push(area);
      }

      const colSize = c.props.size ?? 'auto';
      areaWidths.current.push(colSize);

      areaPropMap.current[colIndex] = {
        size: c.props.size,
        minSize: c.props.minSize,
        maxSize: c.props.maxSize,
        collapsable: c.props.collapsable,
        splitterAt: c.props.splitterAt,
      };

      const splitters: SplitterProperties[] = [];

      // Add a column splitter
      if (c.props.splitterAt) {
        const at = c.props.splitterAt;

        if (at === 'left' || at === 'right') {
          splitters.push({
            at,
            containerIndex: colIndex,
            containerSize: colSize,
            onChange: onAreaWidthChange,
            onUpdate: onAreaWidthUpdate,
          });
        }
      }

      // Add a row splitter
      if (
        splitterAt &&
        index !== undefined &&
        size &&
        onRowHeightChange &&
        onRowHeightUpdate
      ) {
        splitters.push({
          at: splitterAt,
          containerIndex: index,
          containerSize: size,
          onChange: onRowHeightChange,
          onUpdate: onRowHeightUpdate,
        });
      }

      return cloneElement(c, {
        index,
        splitters,
      });
    } else {
      throw new Error('Row can only contain `Area`s as its direct children.');
    }
  });

  if (!cols) {
    throw new Error('Row must have at least one `Area`.');
  }

  return <>{cols}</>;
};

export default Row;
