import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, CircularProgress } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

interface ProcessingButtonsProps {
  isProcessing: boolean;
  isDisabled: boolean;
  onSubmit: () => void;
  onAbort: () => void;
}

const ProcessingButtons: React.FC<ProcessingButtonsProps> = ({
  isProcessing,
  isDisabled,
  onSubmit,
  onAbort,
}) => {
  return (
    <section className={`execute ${isProcessing ? 'processing' : ''}`}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={!isProcessing && <AutoFixHighIcon />}
        fullWidth={true}
        onClick={onSubmit}
        disabled={isDisabled || isProcessing}
      >
        {isProcessing ? (
          <>
            <CircularProgress
              style={{
                marginRight: '8px',
                width: '26px',
                height: '26px',
              }}
              color="inherit"
            />
            <FormattedMessage id="processing" defaultMessage="Processing..." />
          </>
        ) : (
          <FormattedMessage id="btn.execute" defaultMessage="Execute" />
        )}
      </Button>
      {isProcessing && (
        <Button
          variant="contained"
          color="warning"
          size="large"
          fullWidth={true}
          onClick={onAbort}
          disabled={!isProcessing}
        >
          <FormattedMessage id="btn.abort" defaultMessage="Stop" />
        </Button>
      )}
    </section>
  );
};

export default ProcessingButtons;
