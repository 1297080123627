import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ThemeProvider, Typography, createTheme } from '@mui/material';

import AreaGrid from '../common/area-grid/AreaGrid';
import Row from '../common/area-grid/Row';
import Area from '../common/area-grid/Area';
import ActiveLink from '../common/ActiveLink';
import { EvaluationAudioProvider } from './components/customAccordion/EvaluationAudioContext';

const StyledWrapper = styled('div')({
  ul: {
    listStyle: 'none',
    padding: '0',
    position: 'relative',
    paddingTop: '8px',
    paddingBottom: '8px',

    a: {
      color: 'inherit',
      textDecoration: 'none',
      display: 'flex',
      WebkitBoxFlex: '1',
      padding: '8px 16px 8px 16px',

      '&.active': {
        backgroundColor: '#c2c2c2 !important',
        position: 'relative',
        borderRadius: '8px',
      },

      '&:hover': {
        backgroundColor: '#f3f4f6',
        position: 'relative',
        borderRadius: '8px',
      },
    },
  },
});

const TunerPage = () => {
  return (
    <AreaGrid className="tuner">
      <Row>
        <Area
          name="main"
          size="350px"
          minSize="280px"
          maxSize="40%"
          splitterAt="right"
        >
          <aside className="contents sidebar">
            <section className="description">
              <Typography variant="h2" component="h2">
                <FormattedMessage id="tuner.title" defaultMessage="Tuner" />
              </Typography>
            </section>
            <StyledWrapper>
              <ul>
                <ActiveLink to="/tuner/finetune">
                  <FormattedMessage
                    id="tuner.menu.finetune"
                    defaultMessage="Finetune"
                  />
                </ActiveLink>
                <ActiveLink to="/tuner/history">
                  <FormattedMessage
                    id="tuner.menu.history"
                    defaultMessage="History"
                  />
                </ActiveLink>
              </ul>
            </StyledWrapper>
          </aside>
        </Area>
        <Area name="contents">
          <div className="main">
            <ThemeProvider
              theme={createTheme({
                components: {
                  MuiMenuItem: {
                    styleOverrides: {
                      root: {
                        fontSize: '0.7rem',
                        lineHeight: '1',

                        '&:hover, &.Mui-selected:hover': {
                          backgroundColor: '#9cafb878',
                        },

                        '&.Mui-selected': {
                          backgroundColor: '#bfd3dc59',
                        },
                      },
                    },
                  },
                  MuiSelect: {
                    styleOverrides: {
                      select: {
                        padding: '7px 10px',
                        backgroundColor: 'white',
                        minHeight: '1em',
                      },
                    },
                  },
                  MuiOutlinedInput: {
                    styleOverrides: {
                      root: {
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#bfd3dc',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: 'solid #bfd3dc 1px',
                        },
                      },
                    },
                  },
                  MuiIconButton: {
                    styleOverrides: {
                      root: {
                        boxShadow: 'none',
                      },
                    },
                  },
                },
              })}
            >
              <EvaluationAudioProvider>
                <Outlet />
              </EvaluationAudioProvider>
            </ThemeProvider>
          </div>
        </Area>
      </Row>
    </AreaGrid>
  );
};

export default TunerPage;
