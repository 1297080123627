import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Button from './common/Button';
import { LanguageContext } from './LanguageProvider';
import useAuth from './auth/useAuth';

const SignIn = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { language } = useContext(LanguageContext);
  const { isLoading, isAuthenticated, error, user, loginWithRedirect } =
    useAuth0();

  const { permissions } = useAuth();

  useEffect(() => {
    if (isLoading || !permissions) {
      return;
    }

    if (user) {
      const from = searchParams.get('from');

      const to =
        from && permissions.length > 0
          ? permissions.includes(from)
            ? from
            : permissions[0]
          : '/';

      navigate(to, { replace: true });
    }
  }, [isLoading, isAuthenticated, user, navigate, searchParams, permissions]);

  useEffect(() => {
    if (error) {
      switch (error.name) {
        case 'user-is-blocked': // Auth0 block
        case 'auth/user-disabled': // Firebase Auth block
          navigate('/message/blocked-account');
          break;

        default:
          navigate('/message/unknown-error');
      }
    }
  }, [error, navigate]);

  const onSignInClick = () => async () => {
    await loginWithRedirect({
      prompt: 'select_account',
      useRefreshTokens: true,
      ui_locales: language,
    });
  };

  return (
    <div className="container signin">
      <div>
        <div className="title">Supertone Studio</div>
        <div>
          {!isLoading ? (
            <Button className="link signin" onClick={onSignInClick()}>
              <span>Sign in</span>
            </Button>
          ) : (
            <div className="lds-facebook">
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
