import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  BugReport as BugReportIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export interface ReportData {
  user?: string;
  problem?: string;
}

interface Props {
  submitCallback?: (data: ReportData) => void;
}

interface UserInfo {
  email: string;
  isSupertone: boolean;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ReportButton = ({ submitCallback }: Props) => {
  const { user } = useAuth0();
  // 사용자 정보
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [problem, setProblem] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!user?.email) return;
    setUserInfo({
      email: user.email,
      isSupertone: user.email.includes('@supertone.ai'),
    });
  }, [user]);

  const handleLinkToGoogleForm = () => {
    if (!userInfo?.isSupertone) {
      window.open(
        'https://docs.google.com/forms/d/12GVoG31F8qnoQogB4VqRYcWQApGVCl0K3o0dNT2a_dU/edit',
        '_blank'
      );
      return;
    } else {
      setIsOpen(true);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await submitCallback?.({
        problem,
        user: userInfo?.email,
      });
      setIsLoading(false);
      setIsOpen(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleClose = () => {
    if (isLoading) return;
    setIsOpen(false);
    setProblem('');
  };

  return (
    <>
      <Button
        variant="outlined"
        color="warning"
        fullWidth
        onClick={handleLinkToGoogleForm}
        startIcon={<BugReportIcon />}
        className="report-button"
        sx={{
          background: '#fff',
          '&:hover': {
            background: '#fff',
            opacity: 0.8,
          },
        }}
      >
        Send Report
      </Button>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <Box component={'div'} sx={{ ...style }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 6,
              right: 6,
              boxShadow: 'none',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Send Report
          </Typography>
          <Typography
            component="p"
            sx={{
              fontSize: '0.8rem',
              mt: 1,
              mb: 2,
            }}
          >
            현재 입력되어 있는 모델, 파라미터 값이 자동으로 전송됩니다.
          </Typography>
          <TextField
            variant="outlined"
            multiline
            fullWidth
            className="report-textfield"
            value={problem}
            placeholder="추가로 설명을 입력하시려면 여기에 입력해주세요."
            rows={4}
            onChange={(e) => setProblem(e.target.value)}
            disabled={isLoading}
            sx={{
              '& textarea': {
                fontSize: '0.9rem',
              },
            }}
          />
          <Stack
            direction="row"
            justifyContent={'flex-end'}
            sx={{
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Submit'
              )}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ReportButton;
