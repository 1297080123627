import styled from '@emotion/styled';

const StyledFinetunePage = styled('div')({
  marginTop: '2rem',

  'h3.MuiTypography-root': {
    fontSize: '1.25rem',
  },

  '@media (min-width: 992px) and (max-width: 1440px)': {
    width: '80%',
  },
  '@media (min-width: 1440px)': {
    width: '1024px',
  },

  '.dropzone': {
    gridArea: 'dropzone',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#297578b3',
  },

  '.dropzone-container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '15px',

    '& > .dropzone': {
      height: '109px',
      marginTop: '0.3rem',
      marginBottom: '0.3rem',
    },

    '& .audio-container': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },

  '.upload': {
    width: '100%',
    display: 'inline-grid',
    gridTemplateAreas: `
      'dropzone selected'
      'guide selected'
    `,
    gridTemplateColumns: '60% 40%',
    gridTemplateRows: '80px 1fr',

    '& > .dropzone': {
      marginRight: '10px',
    },

    '& > .guide': {
      gridArea: 'guide',
      flexDirection: 'column',
      borderRadius: '5px',
      backgroundColor: '#bfd3dc',
      marginTop: '0.5rem',
      maxHeight: '445px',
      overflowY: 'scroll',
      marginRight: '10px',

      '& .MuiListItem-root': {
        paddingTop: '2px',
        paddingBottom: '0',
        wordBreak: 'break-word',
      },

      '.guide-text': {
        display: 'inline',
        paddingRight: '5px',
        color: '#0000008a',
        fontWeight: 'bold',
        fontSize: '13px',
      },

      '.guide-explain': {
        color: '#302d2d75',
        fontSize: '11px',
        fontWeight: 600,
      },

      '& .MuiListItemIcon-root': {
        minWidth: '25px',

        '.audio-icon': {
          color: '#f9f6cdeb',
          fontSize: '24px',
        },
      },

      '& .MuiSvgIcon-root': {
        fontSize: '28px',
        color: '#666666c2',
        paddingRight: '10px',
      },
      '& .MuiListItemText-root': {
        color: 'rgba(0, 0, 0, 0.5)',
        marginTop: '4px',
        marginBottom: '0',

        '& .MuiTypography-root': {
          lineHeight: '8px',
        },
      },

      'hr.MuiDivider-root': {
        margin: '0.5rem 10px',
      },

      '& .MuiListSubheader-root': {
        backgroundColor: '#2975788a',
        color: 'white',
      },
    },

    '& > .selected': {
      gridArea: 'selected',
      flexDirection: 'column',
      borderRadius: '5px',
      backgroundColor: 'white',
      marginTop: '0.5rem',
    },

    '& .MuiListSubheader-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      borderRadius: '5px',
      marginTop: '-8px',
      backgroundColor: '#36789454',
      marginBottom: '5px',
    },
  },

  '.dataset': {
    width: '100%',
    alignItems: 'center',
    display: 'inline-grid',
    gridTemplateAreas: `
      'label input'
    `,
    gridTemplateColumns: '20% 80%',

    '& .MuiFormControl-root': {
      gridArea: 'input',
    },

    '& .MuiTypography-root': {
      gridArea: 'label',
      fontSize: '15px',
      paddingLeft: '5px',
    },

    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      lineHeight: '1em',
      width: '100%',
      fontSize: '15px',
      height: '38px',
    },
  },

  '.option': {
    display: 'inline-grid',
    gridTemplateAreas: `
      'pipeline version'
    `,
    gridTemplateColumns: '60% 40%',
    paddingLeft: '5px',
    width: '100%',

    '& > .pipeline': {
      display: 'inline-grid',
      gridTemplateAreas: `
        'label input'
      `,
      gridTemplateColumns: '33% 67%',
      alignItems: 'center',
      marginRight: '10px',
    },

    ' & > .version': {
      display: 'inline-grid',
      gridTemplateAreas: `
        'label input'
      `,
      gridTemplateColumns: '40% 60%',
      alignItems: 'center',
      marginRight: '5px',
    },

    '& .MuiFormControl-root': {
      gridArea: 'input',
      maxWidth: '250px',
    },

    '& .MuiTypography-root': {
      gridArea: 'label',
      fontSize: '15px',
    },
  },

  '& .MuiSelect-select': {
    fontSize: '15px',
  },

  '& .MuiTooltip-popper': {
    marginTop: '5px',
    maxWidth: '350px !important',
  },
});

export default StyledFinetunePage;
