import { ModelType } from './SvsPage';

export const ParameterType = {
  Slider: 'slider',
  Radio: 'radio',
  Checkbox: 'checkbox',
  Audio: 'audio',
} as const;

export type Parameter<T extends keyof ParameterDefinitions> =
  ParameterDefinitions[T];

export interface ParameterDefinitions {
  key_transpose: {
    type: typeof ParameterType.Slider;
    name: 'key_transpose';
    label: string;
    tooltip: string;
    defaultValue: number;
    minValue: number;
    maxValue: number;
    step: number;
  };

  pitch_variance: {
    type: typeof ParameterType.Slider;
    name: 'pitch_variance';
    label: string;
    tooltip: string;
    defaultValue: number;
    minValue: number;
    maxValue: number;
    step: number;
    minLabel: string;
    maxLabel: string;
  };

  variation_takes: {
    type: typeof ParameterType.Slider;
    name: 'variation_takes';
    label: string;
    tooltip: string;
    defaultValue: number;
    minValue: number;
    maxValue: number;
    step: number;
    minLabel: string;
    maxLabel: string;
  };

  confident_range_shift: {
    type: typeof ParameterType.Checkbox;
    name: 'confident_range_shift';
    label: string;
    tooltip: string;
    defaultValue: boolean;
  };

  legato_limit: {
    type: typeof ParameterType.Slider;
    name: 'legato_limit';
    label: string;
    tooltip: string;
    defaultValue: number;
    minValue: number;
    maxValue: number;
    step: number;
  };

  padding: {
    type: typeof ParameterType.Checkbox;
    name: 'padding';
    label: string;
    tooltip: string;
    defaultValue: boolean;
  };

  use_midi_velocity: {
    type: typeof ParameterType.Checkbox;
    name: 'use_midi_velocity';
    label: string;
    tooltip: string;
    defaultValue: boolean;
  };
}

type ParameterKeys = keyof ParameterDefinitions;
type ParameterMap = { [key in ParameterKeys]: ParameterDefinitions[key] };

const parameterMap: ParameterMap = {
  key_transpose: {
    type: ParameterType.Slider,
    name: 'key_transpose',
    label: 'Pitch Shift',
    tooltip: '반음 단위로 수정',
    defaultValue: 0,
    minValue: -24,
    maxValue: 24,
    step: 1,
  },

  pitch_variance: {
    type: ParameterType.Slider,
    name: 'pitch_variance',
    label: 'Pitch Variance',
    tooltip: '음정 편차 조절',
    defaultValue: 1,
    minValue: 0,
    maxValue: 2,
    step: 0.1,
    minLabel: 'static',
    maxLabel: 'dynamic',
  },

  variation_takes: {
    type: ParameterType.Slider,
    name: 'variation_takes',
    label: 'Variation Takes',
    tooltip: '노트 onset을 랜덤하게 다변화합니다.',
    defaultValue: 1,
    minValue: 1,
    maxValue: 5,
    step: 1,
    minLabel: 'less',
    maxLabel: 'more',
  },

  confident_range_shift: {
    type: ParameterType.Checkbox,
    name: 'confident_range_shift',
    label: 'Confident Range Shift',
    tooltip: '화자 음역대에 맞춰 음정을 조절합니다.',
    defaultValue: false,
  },

  legato_limit: {
    type: ParameterType.Slider,
    name: 'legato_limit',
    label: 'Legato Limit',
    tooltip:
      '인접한 두 노트의 간격이 이 값보다 작으면 이어서 부르게 생성합니다.',
    defaultValue: 200,
    minValue: 0,
    maxValue: 1000,
    step: 10,
  },

  padding: {
    type: ParameterType.Checkbox,
    name: 'padding',
    label: 'Padding',
    tooltip:
      'True: 생성 오디오의 길이가 0초 부터 미디 리전의 끝부분까지의 길이가 되도록 오디오 앞부분을 padding 합니다. False: 미디 리전 길이와 일치하는 오디오가 생성되며, 별도의 padding 이 추가되지 않습니다.',
    defaultValue: false,
  },

  use_midi_velocity: {
    type: ParameterType.Checkbox,
    name: 'use_midi_velocity',
    label: 'Use MIDI Velocity',
    tooltip:
      'True: 미디에 입력된 velocity를 이용해서 생성합니다. False: 모델이 자동으로 velocity를 맞춰서 생성합니다.',
    defaultValue: false,
  },
};

export const getParameters = (modelType: ModelType) => {
  const p: Parameter<keyof ParameterDefinitions>[] = [];

  switch (modelType) {
    case 'sansy-v1':
      addParameter(p, parameterMap.key_transpose);
      addParameter(p, parameterMap.pitch_variance);
      addParameter(p, parameterMap.variation_takes);
      addParameter(p, parameterMap.confident_range_shift);
      addParameter(p, parameterMap.legato_limit);
      addParameter(p, parameterMap.padding);

      break;

    case 'sansy-v1-velocity':
    case 'sansy-v1-stable':
    case 'sansy-v1-stable-thecross':
    case 'sansy-v1-stable-supergirls':
    case 'sansy-v1-stable-score-analysis':
      addParameter(p, parameterMap.key_transpose);
      addParameter(p, parameterMap.pitch_variance);
      addParameter(p, parameterMap.variation_takes);
      addParameter(p, parameterMap.confident_range_shift);
      addParameter(p, parameterMap.legato_limit);
      addParameter(p, parameterMap.padding);
      addParameter(p, parameterMap.use_midi_velocity);

      break;
  }

  return p;
};

const addParameter = (
  parameters: Parameter<keyof ParameterDefinitions>[],
  p: Parameter<keyof ParameterDefinitions>
) => {
  parameters.push({ ...p });
};
