import { ModelType } from './SvsPage';

export const MODEL_NAME: { [key in ModelType]: string } = {
  'sansy-v1': 'SANSY-V1',
  'sansy-v1-velocity': 'SANSY-V1-Velocity',
  'sansy-v1-stable': 'SANSY-V1-Stable',
  'sansy-v1-stable-thecross': 'SANSY-V1-Stable-TheCross',
  'sansy-v1-stable-supergirls': 'SANSY-V1-Stable-SuperGirls',
  'sansy-v1-stable-score-analysis': 'SANSY-V1-Stable-ScoreAnalysis',
};

export const MODEL_ORDER_MAP: { [key in ModelType]: number } = {
  'sansy-v1': 0,
  'sansy-v1-velocity': 1,
  'sansy-v1-stable': 2,
  'sansy-v1-stable-thecross': 3,
  'sansy-v1-stable-supergirls': 4,
  'sansy-v1-stable-score-analysis': 5,
};
