import {
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import {
  UploadFile as UploadFileIcon,
  Delete as DeleteIcon,
  FolderZip as FolderZipIcon,
  FolderOpenOutlined as FolderOpenOutlinedIcon,
  AudioFile as AudioFileIcon,
} from '@mui/icons-material';
import DeleteAudioIcon from '../../../assets/icons/delete.svg';

import { FormattedMessage, useIntl } from 'react-intl';
import DropzoneContainer from '../../../common/DropzoneContainer';
import { Fragment, useEffect, useState } from 'react';
import JSZip from 'jszip';
import { FileWithPath } from 'react-dropzone';
import toast from 'react-hot-toast';
import axios from 'axios';
import { AudioData } from '../../../common/types';

const guideTemplate = [
  {
    text: '모델 이름',
    explanation: 'Template 의 모델 이름이랑 동일해야 합니다.',
    icon: <FolderOpenOutlinedIcon />,
  },
  {
    text: 'language',
    explanation: 'language code (code2) 양식',
    paddingLeft: '55px',
    icon: <FolderOpenOutlinedIcon />,
    tooltip: `'${'https://www.science.co.il/language/Codes.php'}' 참고해 주세요.`,
  },
  {
    text: 'dataset_1_eval ',
    explanation: '_eval 은 평가용에만 붙여주세요.',
    paddingLeft: '85px',
    icon: <FolderOpenOutlinedIcon />,
  },
  {
    text: 'source',
    explanation: '평가용에만 존재합니다.',
    paddingLeft: '115px',
    icon: <FolderOpenOutlinedIcon />,
  },
  {
    text: '오디오 파일들',
    paddingLeft: '145px',
    icon: <AudioFileIcon className="audio-icon" />,
  },
  {
    text: 'target',
    explanation: '평가용에만 존재합니다.',
    paddingLeft: '115px',
    icon: <FolderOpenOutlinedIcon />,
  },
  {
    text: '오디오 파일들',
    paddingLeft: '145px',
    icon: <AudioFileIcon className="audio-icon" />,
  },
  {
    text: 'dataset_2',
    explanation: '일반 데이터 셋은 스피커 이름을 하위 폴더로 설정해 주세요.',
    paddingLeft: '85px',
    icon: <FolderOpenOutlinedIcon />,
  },
  {
    text: '스피커이름',
    paddingLeft: '115px',
    icon: <FolderOpenOutlinedIcon />,
  },
  {
    text: '오디오 파일들',
    paddingLeft: '145px',
    icon: <AudioFileIcon className="audio-icon" />,
  },
  {
    text: 'dataset_1',
    paddingLeft: '85px',
    icon: <FolderOpenOutlinedIcon />,
  },
  {
    text: 'dataset_3',
    paddingLeft: '85px',
    icon: <FolderOpenOutlinedIcon />,
  },
];

const DatasetSection = ({
  modelName,
  datasetFileList,
  onChangeValid,
  onChangeModelName,
  onChangeDatasetFileList,
  resetUploadUrl,
  showStructure,
}: {
  modelName: string;
  datasetFileList: File | undefined;
  onChangeValid: (value: boolean) => void;
  onChangeModelName: (value: string) => void;
  onChangeDatasetFileList: (value: File | undefined) => void;
  resetUploadUrl: () => void;
  showStructure: boolean;
}) => {
  const { formatMessage } = useIntl();
  const [audioData, setAudioData] = useState<AudioData | null>(null);
  const tunerBaseURL = process.env.REACT_APP_TUNER_API_HOST;

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 350,
      marginTop: '5px !important',
      inset: '0px auto auto -107px',
    },
  });

  const invalidZipFileTypeErrorMessage = formatMessage({
    id: 'err.invalid-file-type',
    defaultMessage:
      'Tuner는 Zip 파일만 지원하고 있습니다. Zip 파일로 다시 업로드해주세요.',
  });

  const invalidFileTypeErrorMessage = formatMessage({
    id: 'err.invalid-rtvc-file-type',
    defaultMessage:
      'RTVC는 WAV 파일만 지원하고 있습니다. WAV 파일로 다시 업로드해주세요.',
  });

  useEffect(() => {
    if (showStructure) {
      setAudioData(null);
    }
  }, [showStructure]);

  const onChangeDatasetZipFile = async (acceptedFiles: FileWithPath[]) => {
    const acceptedFile = acceptedFiles[0];
    onChangeDatasetFileList(acceptedFile);

    // Extract the depth of the file.
    JSZip.loadAsync(acceptedFile)
      .then((zip) => {
        const fileList = Object.values(zip.files)
          .filter(
            (file) =>
              !file.dir &&
              !file.name.startsWith('__MACOSX/') &&
              (file.name.endsWith('.wav') || file.name.endsWith('.mp3'))
          )
          .map((file) => file.name);

        // call validation api
        validationCheck(fileList);
      })
      .catch(function (err) {
        toast.error(err);
      });
  };

  const onChangeDatasetWavFile = async (acceptedFiles: FileWithPath[]) => {
    const file = acceptedFiles[0];
    validationCheck([file.name]);

    setAudioData({
      file: new File([file], file.name, { type: 'audio/wav' }),
      type: 'file',
      name: file.name,
    });
    onChangeDatasetFileList(file);
  };

  useEffect(() => {
    if (audioData) {
      const url = URL.createObjectURL(audioData.file);
      const player = document.getElementById(
        'audio-player'
      ) as HTMLAudioElement;

      // 오디오 플레이어 설정
      if (player) player.src = url;
    }
  }, [audioData]);

  const onAudioDelete = () => {
    setAudioData(null);
  };

  const validationCheck = async (fileList: string[]) => {
    try {
      onChangeValid(false);
      const { data } = await axios.post(
        `${tunerBaseURL}/v1/dataset/validate/${showStructure ? 'cvc' : 'rtvc'}`,
        fileList
      );

      onChangeValid(data.valid);

      if (!data.valid) {
        const errorMessage = data.message
          ? data.message
          : showStructure
          ? formatMessage({
              id: 'tuner.msg.zipFileInValid',
              defaultMessage: 'Please check Zip file structure.',
            })
          : formatMessage({
              id: 'tuner.msg.fileInValid',
              defaultMessage: '파일이 유효하지 않습니다. 다시 선택해주세요.',
            });

        toast.error(errorMessage);
        onChangeValid(false);
      }
    } catch (err: any) {
      toast.error(err);
      onChangeValid(false);
    }
  };

  const onDeleteFileClick = () => {
    onChangeDatasetFileList(undefined);
    resetUploadUrl();
    onChangeValid(false);
    onChangeModelName('');
  };

  return (
    <>
      <section>
        <Stack
          alignItems="flex-start"
          justifyContent="space-between"
          direction="row"
        >
          <Typography variant="body1" component="span">
            <FormattedMessage
              id="ftuner.inetune.dataset"
              defaultMessage="Dataset"
            />
          </Typography>
        </Stack>
        {!showStructure && (
          <div className="dropzone-container">
            <DropzoneContainer
              successCallback={onChangeDatasetWavFile}
              invalidFileTypeErrorMessage={invalidFileTypeErrorMessage}
              accept={{
                'audio/*': [],
              }}
            >
              <p>
                <UploadFileIcon fontSize="small" />
                <FormattedMessage
                  id="tuner.finetune.rtvc.dropzone"
                  defaultMessage="Drop wav files or click here"
                />
              </p>
            </DropzoneContainer>
            {audioData && (
              <div className="audio-container">
                <span>{audioData.name}</span>
                <button className="btn-delete" onClick={onAudioDelete}>
                  <img src={DeleteAudioIcon} alt="Delete source audio." />
                </button>
                <audio controls id="audio-player" title={audioData.name} />
              </div>
            )}
          </div>
        )}
        {showStructure && (
          <div className="upload">
            <DropzoneContainer
              successCallback={onChangeDatasetZipFile}
              invalidFileTypeErrorMessage={invalidZipFileTypeErrorMessage}
              accept={{
                'application/zip': [],
              }}
            >
              <p>
                <UploadFileIcon fontSize="small" />
                <FormattedMessage
                  id="tuner.finetune.dropzone"
                  defaultMessage="Drop zip files or click here"
                />
              </p>
            </DropzoneContainer>
            <div className="selected">
              <List dense={true}>
                <ListSubheader>
                  <FormattedMessage
                    id="tuner.finetune.uploadFileList"
                    defaultMessage="List of files to be uploaded."
                  />
                </ListSubheader>
                {datasetFileList && (
                  <ListItem
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => onDeleteFileClick()}
                        sx={{ color: '#206366ba' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemIcon>
                      <FolderZipIcon sx={{ color: '#666666c2' }} />
                    </ListItemIcon>
                    <ListItemText primary={datasetFileList.name} />
                  </ListItem>
                )}
              </List>
            </div>

            <div className="guide">
              <List dense={true}>
                <ListSubheader>
                  <FormattedMessage
                    id="tuner.finetune.uploadFileStructure"
                    defaultMessage="Please follow the format below for the ZIP file structure."
                  />
                </ListSubheader>
                {guideTemplate.map((item, index) => (
                  <ListItem key={index} sx={{ paddingLeft: item.paddingLeft }}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    {item.tooltip ? (
                      <CustomTooltip
                        title={item.tooltip}
                        arrow
                        placement="bottom-start"
                      >
                        <ListItemText
                          primary={
                            <Fragment>
                              <Typography
                                component="span"
                                className="guide-text"
                              >
                                {item.text}
                              </Typography>
                            </Fragment>
                          }
                          secondary={
                            <Typography
                              component="span"
                              className="guide-explain"
                            >
                              {item.explanation}
                            </Typography>
                          }
                        />
                      </CustomTooltip>
                    ) : (
                      <ListItemText
                        primary={
                          <Fragment>
                            <Typography component="span" className="guide-text">
                              {item.text}
                            </Typography>
                          </Fragment>
                        }
                        secondary={
                          <Typography
                            component="span"
                            className="guide-explain"
                          >
                            {item.explanation}
                          </Typography>
                        }
                      />
                    )}
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        )}
      </section>
      <section>
        <div className="dataset">
          <Typography variant="body1" component="span">
            <FormattedMessage
              id="tuner.finetune.modelName"
              defaultMessage="Model Name"
            />
          </Typography>
          <FormControl>
            <TextField autoComplete="off" value={modelName} disabled />
          </FormControl>
        </div>
      </section>
    </>
  );
};

export default DatasetSection;
