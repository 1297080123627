import React from 'react';

interface Props {
  id: string;
  checked: boolean;
  onChange: () => void;
}

const ToggleSwitch = ({ id, checked, onChange }: Props) => {
  return (
    <span className="switch">
      <input type="checkbox" id={id} checked={checked} onChange={onChange} />
      <span className="slider" aria-hidden="true" />
    </span>
  );
};

export default ToggleSwitch;
