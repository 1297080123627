import React, { PropsWithChildren, useRef } from 'react';

interface Props {
  className?: string;
  onClick?: () => void;
  preventDoubleClick?: boolean;
}

// Duration between each click to prevent double clicks.
const DOUBLE_CLICK_THRESHOLD = 300;

const Button = ({
  className,
  onClick,
  preventDoubleClick = true,
  children,
}: PropsWithChildren<Props>) => {
  const lastClicked = useRef(0);

  const onButtonClick = () => {
    if (!onClick) return;

    const now = Date.now();
    const delta = now - lastClicked.current;

    if (!preventDoubleClick || delta > DOUBLE_CLICK_THRESHOLD) {
      lastClicked.current = now;
      onClick();
    }
  };

  return (
    <button className={className} onClick={onButtonClick}>
      {children}
    </button>
  );
};

export default Button;
