import styled from '@emotion/styled';
import { useEffect, useRef, ChangeEvent } from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  useGridApiContext,
} from '@mui/x-data-grid';
import CustomTableAccordion from '../../components/customAccordion/CustomTableAccordion';

const StyledWrapper = styled('section')({
  '& .MuiDataGrid-footerContainer': {
    display: 'none',
  },
  '.config-input': {
    border: 'none',
    outline: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    width: '100%',
    padding: '0 0 0 10px',
    resize: 'none',
  },
});

export type ConfigTable = {
  id: string;
  config: string;
  value: number | string | boolean;
};

export type ConfigData = {
  [key: string]: number | string | boolean;
};

type ConfigSectionProps = {
  configData: Record<string, ConfigData>;
  onConfigDataUpdate: (section: string, updatedData: ConfigTable) => void;
};

const ConfigSection = ({
  configData,
  onConfigDataUpdate,
}: ConfigSectionProps) => {
  const MultilineInput = (props: GridRenderCellParams<any, string>) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    const ref = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      if (ref.current) {
        const length = ref.current.value.length;

        // Set the cursor to the end
        ref.current.setSelectionRange(length, length);

        // Set the rows dynamically
        const contentRows = Math.max(
          1,
          Math.floor(ref.current.scrollHeight / 20)
        );

        ref.current.rows = contentRows;
        ref.current.style.height = `${Math.max(contentRows, 1) * 20}px`;
        ref.current.focus();
      }
    }, []);

    const handleValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    };

    return (
      <textarea
        ref={ref}
        className="config-input"
        value={value}
        onChange={handleValueChange}
        rows={1}
      />
    );
  };

  const renderSelectEditInputCell: GridColDef['renderCell'] = (params) => {
    return <MultilineInput {...params} />;
  };

  const columns: GridColDef[] = [
    {
      field: 'config',
      headerName: 'Field',
      editable: false,
      align: 'left',
      headerAlign: 'left',
      width: 600,
    },
    {
      field: 'value',
      headerName: 'Value',
      editable: true,
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      renderEditCell: renderSelectEditInputCell,
    },
  ];

  const createRows = (data: ConfigData): GridRowsProp =>
    Object.entries(data).map(([config, value], index) => ({
      id: index + 1,
      config,
      value,
    }));

  return (
    <StyledWrapper className="config">
      <CustomTableAccordion
        configSections={configData}
        columns={columns}
        createRows={createRows}
        onConfigDataUpdate={onConfigDataUpdate}
      />
    </StyledWrapper>
  );
};

export default ConfigSection;
