import { styled } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid';
import CustomToolbar from './CustomToolbar';
import CustomPagination from './CustomPagination';
import { Checkbox } from '@mui/material';
import { RunningData } from '../../menu/HistoryPage';

const StyledDataGrid = styled(DataGrid)(() => ({
  border: 0,
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-main': {
    backgroundColor: 'white',
    borderRadius: '10px',
  },
  '& .MuiDataGrid-root': {
    maxWidth: '3000px',
    margin: '0 auto',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    '&:focus, &:focus-within': {
      outline: 'solid #bfd3dc 1px',
    },
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },

  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid #f0f0f0`,
    backgroundColor: '#36789454',
    color: '#287578',
    '& .MuiCheckbox-root.Mui-checked': {
      color: '#297578',
    },
  },
  '.MuiDataGrid-toolbarContainer': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    paddingBottom: '5px',
  },
  '.MuiDataGrid-cellCheckbox': {
    justifyContent: 'center',

    '& .MuiCheckbox-root.Mui-checked': {
      color: '#297578',
    },
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: '#bfd3dc3d',

    '&:hover': {
      backgroundColor: '#aec2cc59',
    },
  },

  '& .MuiPaginationItem-root.Mui-selected': {
    backgroundColor: '#bfd3dcd6',
    border: '1px solid rgb(41 117 120 / 54%)',
    color: 'black',
  },
}));

const CustomDataGrid = ({
  rows,
  columns,
  isLoading,
  onPauseClick,
  isProcessingPause,
}: {
  rows: RunningData[];
  columns: GridColDef[];
  isLoading: boolean;
  onPauseClick: (selectedRows: GridRowId[]) => void;
  isProcessingPause: boolean;
}) => {
  const apiRef = useGridApiRef();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [currentPageRowIds, setCurrentPageRowIds] = useState<GridRowId[]>([]);

  useEffect(() => {
    const sortedRowIds = apiRef.current.getSortedRowIds();
    const { page, pageSize } = paginationModel;

    // calculate the current page's row ids by pageSize and index
    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;
    const newCurrentPageRowIds = sortedRowIds.slice(startIndex, endIndex);

    setCurrentPageRowIds(newCurrentPageRowIds);
  }, [paginationModel, rows]);

  const hasRunningState = useMemo(() => {
    // Check if any selected rows have a status of 'Running'
    return selectionModel.some((rowId) => {
      const selectedRow = rows.find((row: RunningData) => row.id === rowId);
      return selectedRow && selectedRow.status === 'Running';
    });
  }, [selectionModel, rows]);

  const calculateAllRowsSelected = useMemo(() => {
    return () => {
      if (currentPageRowIds.length === 0) {
        return false;
      }
      return currentPageRowIds.every((rowId) => selectionModel.includes(rowId));
    };
  }, [currentPageRowIds, selectionModel]);

  const onSelectAllClick = () => {
    const allRowsSelected = calculateAllRowsSelected();

    setSelectionModel((prevSelection) => {
      if (allRowsSelected) {
        // If all rows are selected, remove them from the selected rows
        return prevSelection.filter(
          (rowId) => !currentPageRowIds.includes(rowId)
        );
      } else {
        // If not all rows are selected, update the selected rows to current page rows
        return [...new Set([...(prevSelection || []), ...currentPageRowIds])];
      }
    });
  };

  const onSelectionChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
  };

  const onPaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const customHeader = useMemo(() => {
    const allRowsSelected = calculateAllRowsSelected();

    return columns.map((column) =>
      column.field === '__check__'
        ? {
            ...column,
            renderHeader: () => (
              <Checkbox checked={allRowsSelected} onChange={onSelectAllClick} />
            ),
          }
        : column
    );
  }, [selectionModel, paginationModel, currentPageRowIds]);

  return (
    <StyledDataGrid
      autoHeight
      checkboxSelection
      disableRowSelectionOnClick
      hideFooterSelectedRowCount
      rows={rows}
      columns={customHeader}
      apiRef={apiRef}
      rowSelectionModel={selectionModel}
      slots={{
        pagination: CustomPagination,
        toolbar: () => (
          <CustomToolbar
            selectedRowCount={selectionModel.length}
            onPauseClick={() => onPauseClick(selectionModel)}
            isDisabled={selectionModel.length === 0 || !hasRunningState}
            isProcessingPause={isProcessingPause}
          />
        ),
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'time', sort: 'desc' }],
        },
        pagination: { paginationModel },
      }}
      onPaginationModelChange={onPaginationModelChange}
      onRowSelectionModelChange={onSelectionChange}
      loading={isLoading}
    />
  );
};

export default CustomDataGrid;
