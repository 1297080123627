import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useRef,
} from 'react';
import WaveSurfer from 'wavesurfer.js';

interface WaveformContextProps {
  addWaveform: (Waveform: WaveSurfer) => void;
  removeWaveform: (Waveform: WaveSurfer) => void;
  pauseOtherWaveforms: () => void;
}

const WaveformContext = createContext<WaveformContextProps>({
  addWaveform: () => {},
  removeWaveform: () => {},
  pauseOtherWaveforms: () => {},
});

const WaveformProvider = ({ children }: PropsWithChildren) => {
  const waveforms = useRef<WaveSurfer[]>([]);

  const pauseOtherWaveforms = useCallback(() => {
    waveforms.current.forEach((waveform) => {
      if (waveform.isPlaying()) {
        waveform.pause();
      }
    });
  }, [waveforms]);

  const addWaveform = useCallback((waveform: WaveSurfer) => {
    waveforms.current.push(waveform);
  }, []);

  const removeWaveform = useCallback(
    (waveform: WaveSurfer) => {
      waveforms.current = waveforms.current.filter((ws) => ws !== waveform);
    },
    [waveforms]
  );

  return (
    <WaveformContext.Provider
      value={{ addWaveform, removeWaveform, pauseOtherWaveforms }}
    >
      {children}
    </WaveformContext.Provider>
  );
};

export { WaveformContext, WaveformProvider };
