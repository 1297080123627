import styled from '@emotion/styled';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

import {
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { Stack, Typography } from '@mui/material';
import {
  ZoomIn as ZoomInIcon,
  OpenInNew as OpenInNewIcon,
  CheckCircle as CheckCircleIcon,
  UnpublishedOutlined as UnpublishedOutlinedIcon,
} from '@mui/icons-material';

import CustomDataGrid from '../components/customDataGrid/CustomDataGrid';
import { useEffect, useState } from 'react';
import axios from 'axios';

const StyledWrapper = styled('div')({
  marginTop: '2rem',

  'h3.MuiTypography-root': {
    fontSize: '1.25rem',
  },
  'a.Succeeded:visited': {
    color: '#021bf5',
  },
  'a.Failed:visited': {
    color: '#f44336',
  },
  'a.Running:visited': {
    color: '#4caf50',
  },
});

const StatusCell = styled('div')({
  '&.status.Succeeded': {
    color: '#414fc6fa',
  },
  '&.status.Failed': {
    color: '#d65c53',
  },
  '&.status.Running': {
    color: '#2e8532e3',
  },
});

export type RunningData = {
  id: string;
  name: string;
  status: string;
  dataset: {
    dataset_name: string;
    path: string;
  };
  pipeline: {
    name: string;
    version: {
      version: string;
      pipeline_version_id: string;
    };
  };
  selected_steps: number;
  scheduled_at: string;
  kubeflow_running_url: string;
};

const HistoryPage = () => {
  const { formatMessage } = useIntl();
  const tunerBaseURL = process.env.REACT_APP_TUNER_API_HOST;

  const [rowData, setRowData] = useState<RunningData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    getRunningData();
  }, []);

  const getRunningData = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(tunerBaseURL + '/v1/running');

      const formattedData = data.runnings.map((running: any) => ({
        ...running,
        id: running.running_id,
      }));

      setRowData(formattedData);
    } catch (err) {
      toast.error(
        formatMessage({
          id: 'tuner.msg.error-get-running-info',
          defaultMessage: 'Failed to retrieve running data.',
        })
      );
    } finally {
      setIsLoading(false);
      setIsProcessing(false);
    }
  };

  const columns: GridColDef[] = [
    {
      field: '__check__',
      type: 'singleSelect',
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      filterable: false,
    },
    {
      field: 'scheduled_at',
      headerName: 'Time',
      maxWidth: 240,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const date = new Date(params.row.scheduled_at);

        return <div>{date.toLocaleString()}</div>;
      },
    },
    {
      field: 'dataset',
      headerName: 'Model',
      minWidth: 300,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <div>{params.row.dataset?.dataset_name}</div>
            {params.row.status === 'Running' && (
              <a
                href={params.row.kubeflow_running_url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <OpenInNewIcon
                  sx={{
                    marginLeft: '5px',
                    marginTop: '6px',
                    color: '#99baf2',
                    transform: ' scale(0.8)',
                  }}
                />
              </a>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'pipeline',
      headerName: 'Pipeline',
      minWidth: 300,
      flex: 1,
      valueGetter: (params) => {
        const pipelineName = params.row.pipeline?.name || '';
        const pipelineVersion = params.row.pipeline?.version?.version || '';
        return `${pipelineName} - ${pipelineVersion}`;
      },
    },
    {
      field: 'selected_steps',
      headerName: 'Selected',
      maxWidth: 140,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.selected_steps !== 0 ? (
          <CheckCircleIcon sx={{ transform: ' scale(0.8)' }} />
        ) : (
          <UnpublishedOutlinedIcon sx={{ transform: ' scale(0.8)' }} />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      maxWidth: 150,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const status = params.row.status;
        const isRtvc = params.row.pipeline?.name.startsWith('rtvc-');

        return status === 'Succeeded' ? (
          <Link
            to={`/tuner/evaluation/${params.row.id}`}
            state={isRtvc}
            className={`status-link ${status}`}
            style={{ textDecoration: 'none' }}
          >
            <Stack direction="row">
              <StatusCell
                className={`status ${status}`}
                style={{ textDecoration: 'none' }}
              >
                {status}
              </StatusCell>
              {status === 'Succeeded' && (
                <ZoomInIcon sx={{ marginLeft: 2, color: '#414fc6fa' }} />
              )}
            </Stack>
          </Link>
        ) : (
          <Stack direction="row">
            <StatusCell className={`status ${status}`}>{status}</StatusCell>
          </Stack>
        );
      },
    },
  ];

  const onPauseClick = async (selectionModel: GridRowSelectionModel) => {
    const requestData = { running_ids: selectionModel };

    try {
      setIsProcessing(true);

      await axios.post(tunerBaseURL + '/v1/running/terminate/', requestData);

      await getRunningData();
    } catch (e: any) {
      const failedRunning = rowData.find((running: RunningData) =>
        selectionModel.includes(running.id)
      );

      const datasetName = failedRunning?.dataset?.dataset_name;
      const errorMessage = formatMessage(
        {
          id: 'tuner.msg.error-pause-running',
          defaultMessage: 'Failed to pause running for dataset {datasetName}.',
        },
        { datasetName }
      );

      toast.error(errorMessage);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <StyledWrapper>
      <section>
        <Stack
          alignItems="flex-start"
          justifyContent="space-between"
          direction="row"
        >
          <Typography variant="h3">
            <FormattedMessage
              id="tuner.menu.history"
              defaultMessage="History"
            />
          </Typography>
        </Stack>
      </section>
      <div>
        <CustomDataGrid
          rows={rowData}
          columns={columns}
          isLoading={isLoading}
          onPauseClick={onPauseClick}
          isProcessingPause={isProcessing}
        />
      </div>
    </StyledWrapper>
  );
};

export default HistoryPage;
