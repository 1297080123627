import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LanguageContext } from '../LanguageProvider';
import ndaMessages from './nda.json';

type LanguageType = 'en' | 'ko';

const NDAPage = () => {
  const { language } = useContext(LanguageContext);
  const [searchParams] = useSearchParams();

  const handleClickAgree = () => {
    const state = searchParams.get('state');

    window.location.replace(
      `${process.env.REACT_APP_AUTH0_HOST}/continue?state=${state}`
    );
  };

  const messages = ndaMessages[language as LanguageType];

  return (
    <div style={{ margin: '0 5rem' }}>
      {messages.map((message, index) => (
        <div key={index}>
          {message}
          <br />
        </div>
      ))}
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        <button onClick={handleClickAgree}>Agree</button>
      </div>
    </div>
  );
};

export default NDAPage;
