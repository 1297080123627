import { useCallback, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { FormattedMessage, useIntl } from 'react-intl';
import toast from 'react-hot-toast';
import cuid from 'cuid';
import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  UploadFile as UploadFileIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from '@mui/icons-material';

import {
  formatReportData,
  resetControllers,
  sendBlobRequest,
  sendReport,
  abortBlobRequestAll,
} from '../util/api';
import { addSuffixToFileName, removeFileSuffix } from '../util/string';
import AudioCard from '../common/AudioCard';
import ErrorToast from '../common/ErrorToast';
import DropzoneContainer from '../common/DropzoneContainer';
import ReportButton, { ReportData } from '../common/ReportButton';
import { WaveformProvider } from '../common/waveform/WaveformContext';
import { Checkbox } from '@mui/material';
import AreaGrid from '../common/area-grid/AreaGrid';
import Row from '../common/area-grid/Row';
import Area from '../common/area-grid/Area';
import ProcessingButtons from '../components/ProcessingButtons';
import { getAudioDurationFromFile } from '../util/audio';
import { MAX_FILE_SIZE } from '../consts';

interface AudioData {
  id: string;
  file: FileWithPath;
  type: 'file';
  name: string;
}

interface AudioResult {
  id: string;
  date: Date;
  audioData: Blob;
  name: string;
}

type ModelGroupType = 'mss' | 'experimental';

type ModelGroup = {
  label: string;
  modelTypes: ModelType[];
};

type ModelType = 'vocals' | 'accomp' | 'vocals-dereverb' | 'sqse-v2';

type Model = {
  params: {
    api: string;
    model_type: string;
  };
  label: string;
  suffix: string;
  modelFilter?: string;
};

const modelGroupMap: Record<string, ModelGroup> = {
  mss: {
    label: 'Music Source Separation',
    modelTypes: ['vocals', 'accomp', 'vocals-dereverb'],
  },
  experimental: {
    label: 'Experimental',
    modelTypes: ['sqse-v2'],
  },
};

const modelMap: Record<ModelType, Model> = {
  vocals: {
    params: {
      api: 'mss',
      model_type: 'vocals',
    },
    label: 'Vocals',
    suffix: '_vocals',
  },
  accomp: {
    params: {
      api: 'mss',
      model_type: 'accomp',
    },
    label: 'Instrumental',
    suffix: '_instrumental',
  },
  'vocals-dereverb': {
    params: {
      api: 'se',
      model_type: 'hifi_brunet',
    },
    label: 'Vocals Dereverb',
    suffix: '_dereverb',
  },
  'sqse-v2': {
    params: {
      api: 'sqse',
      model_type: 'sqse-v2',
    },
    label: 'SQSE V2 (Voice Only)',
    suffix: '_sqse_v2',
    modelFilter: '/sqse/model/sqse-v2',
  },
};

const modelGroups = Object.keys(modelGroupMap) as ModelGroupType[];
const models = Object.keys(modelMap) as ModelType[];

const API_PATH_MSS = process.env.REACT_APP_API_PATH_MSS!;
const API_PATH_SQSE = process.env.REACT_APP_API_PATH_SQSE!;

const MssPage = () => {
  const { formatMessage } = useIntl();

  // Model type
  const [processingModelType, setProcessingModelType] = useState<ModelType>();
  const [selectedModelTypes, setSelectedModelTypes] = useState<ModelType[]>([]);

  // 소스 오디오
  const [sourceAudios, setSourceAudios] = useState<AudioData[]>([]);

  // 결과 처리 중 여부
  const [isProcessing, setIsProcessing] = useState(false);

  // 오류 발생 여부
  const [isError, setIsError] = useState(false);

  // 결과
  const [results, setResults] = useState<AudioResult[]>([]);

  // 소스 오디오 파일 선택 시 (복수 선택 가능)
  const onSourceAudiosSelect = (files: FileWithPath[]) => {
    const list: AudioData[] = files.map((f) => ({
      id: cuid(),
      file: f,
      type: 'file',
      name: f.name,
    }));

    setSourceAudios([...sourceAudios, ...list]);
  };

  // 소스 오디오 파일 삭제 시
  const onSourceAudioDelete = (index: number) => () => {
    const files = sourceAudios.filter((_, i) => i !== index);
    setSourceAudios(files);
  };

  // 소스 오디오 파일 전체 삭제 시
  const onAllSourceAudioDelete = () => {
    setSourceAudios([]);
  };

  const onModelTypeCheck = useCallback(
    (model: ModelType) => {
      const types = [...selectedModelTypes];

      if (types.includes(model)) {
        types.splice(types.indexOf(model), 1);
      } else {
        types.push(model);
      }

      setSelectedModelTypes(types);
    },
    [selectedModelTypes]
  );

  const getResultObj = useCallback((data: Blob, name: string): AudioResult => {
    return {
      id: cuid(),
      date: new Date(),
      audioData: data,
      name,
    };
  }, []);

  const submit = useCallback(
    async (sourceAudio: AudioData, selectModelType: ModelType) => {
      let sourceFile: Blob = sourceAudio.file;
      let sourceName = sourceAudio.name;
      if (!sourceFile) return;
      switch (selectModelType) {
        case 'vocals':
        case 'accomp':
          const processingModel = modelMap[selectModelType];
          const data = {
            ...processingModel.params,
            src: sourceFile,
          };

          const r = await sendBlobRequest(API_PATH_MSS, data);
          return getResultObj(
            r.data,
            `${removeFileSuffix(
              addSuffixToFileName(sourceName, processingModel.suffix)
            )}.wav`
          );

        case 'vocals-dereverb': {
          let data: Record<string, any> = {
            ...modelMap.vocals.params,
            src: sourceFile,
          };

          let r = await sendBlobRequest(API_PATH_MSS, data);

          sourceFile = new Blob([r.data], { type: 'audio/wav' });
          sourceName = `${removeFileSuffix(
            addSuffixToFileName(sourceName, modelMap.vocals.suffix)
          )}.wav`;
          const processingModel = modelMap[selectModelType];
          data = {
            ...processingModel.params,
            out_type: 'dry',
            src: sourceFile,
          };

          r = await sendBlobRequest(API_PATH_MSS, data);
          return getResultObj(
            r.data,
            `${removeFileSuffix(
              addSuffixToFileName(sourceName, processingModel.suffix)
            )}.wav`
          );
        }

        case 'sqse-v2': {
          const duration = await getAudioDurationFromFile(sourceFile);

          // Accept only wav file with less than 240s duration.
          if (duration > 240) {
            toast.error('File duration must be less than 240s.');

            return;
          }

          const data = {
            ...modelMap['sqse-v2'].params,
            src: sourceFile,
          };

          const r = await sendBlobRequest(API_PATH_SQSE, data);

          return getResultObj(
            r.data,
            `${removeFileSuffix(
              addSuffixToFileName(sourceName, modelMap['sqse-v2'].suffix)
            )}.wav`
          );
        }
      }
    },
    [getResultObj]
  );

  const processFiles = useCallback(
    async (selectModelType: ModelType) => {
      try {
        setIsProcessing(true);
        // [workaround] second parameter for recursive call exception at hook
        const rs = await Promise.all(
          sourceAudios.map((audio) => submit(audio, selectModelType))
        );
        const list = rs.reduce<AudioResult[]>((acc, r) => {
          if (r) acc.push(r);
          return acc;
        }, []);
        setResults((results) => [...list, ...results]);
      } catch (e: any) {
        if (e?.code === 'ERR_CANCELED') {
          return e.code;
        }
        setIsError(true);
        console.error(e);
        // toast.error('error');
      } finally {
        resetControllers();
        setIsProcessing(false);
      }
    },
    [sourceAudios, submit]
  );

  const process = useCallback(async () => {
    let i = 0;
    let len = selectedModelTypes.length;
    let result;
    while (result !== 'ERR_CANCELED' && i < len) {
      const selectModelType = selectedModelTypes[i];
      if (selectModelType) {
        setProcessingModelType(selectModelType);
        result = await processFiles(selectModelType);
      }
      i++;
    }
  }, [processFiles, selectedModelTypes, setProcessingModelType]);

  const onSubmit = useCallback(() => {
    if (sourceAudios.length === 0) {
      toast.error(
        formatMessage({
          id: 'cvc.msg.source-audio-needed',
          defaultMessage: 'Please select a source audio.',
        })
      );
      return;
    }
    process();
  }, [process, sourceAudios, formatMessage]);

  // 결과 삭제 시
  const onResultDelete = useCallback(
    (index: number) => () => {
      const files = results.filter((_, i) => i !== index);
      setResults(files);
    },
    [setResults, results]
  );

  // error report용 데이터. vocals-dereverb의 경우 변수가 있어서 하드코딩
  const getRequestData = useCallback(() => {
    switch (processingModelType) {
      case 'vocals':
      case 'accomp':
        return;
      case 'vocals-dereverb':
        return {
          ...modelMap[processingModelType].params,
          out_type: 'dry',
        };

      default:
        return {};
    }
  }, [processingModelType]);

  const handleReportSubmit = useCallback(
    async ({ user, problem }: ReportData) => {
      const inputData: Record<string, any> = {
        ...getRequestData(),
        user,
        problem,
      };

      if (sourceAudios?.length) {
        inputData.src = [...sourceAudios];
      }

      const data = formatReportData(inputData);
      const r = await sendReport(data);
      return r;
    },
    [getRequestData, sourceAudios]
  );

  const abort = useCallback(() => {
    abortBlobRequestAll();
    setIsProcessing(false);
    resetControllers();
  }, [setIsProcessing, resetControllers]);

  return (
    <AreaGrid className="mss">
      <Row>
        <Area
          name="main"
          size="350px"
          minSize="280px"
          maxSize="40%"
          splitterAt="right"
        >
          <aside className="contents sidebar">
            <section className="description">
              <Typography variant="h2" component="h2">
                Music Source Seperation and Speech Enhancement
              </Typography>
              <Typography variant="body1">
                음원에서 목소리와 목소리 아닌 것을 분리하고, 필요한 것을
                선택하여 작업할 수 있습니다. 추가로 변화된 음원의 소음을 줄일 수
                있습니다.
              </Typography>
            </section>

            <div className="parameters">
              <FormControl>
                {modelGroups.map((group) => {
                  const { label, modelTypes } = modelGroupMap[group];

                  return (
                    <section key={group}>
                      <div className="model-group-name">{label}</div>

                      <div className="model-types">
                        {modelTypes.map((model, index) => {
                          // const modelInfo = modelMap[model];

                          // if (modelInfo.modelFilter) {
                          //   if (
                          //     !(permissions ?? []).includes(
                          //       modelInfo.modelFilter
                          //     )
                          //   ) {
                          //     return null;
                          //   }
                          // }

                          return (
                            <FormControlLabel
                              key={model}
                              control={
                                <Checkbox
                                  name={`mss-type-${model}`}
                                  checked={!!selectedModelTypes.includes(model)}
                                  onChange={() => onModelTypeCheck(model)}
                                  value={model}
                                />
                              }
                              label={modelMap[model].label}
                            />
                          );
                        })}
                      </div>
                    </section>
                  );
                })}
              </FormControl>
            </div>
          </aside>
        </Area>
        <Area name="contents">
          <div className="main">
            <WaveformProvider>
              <div className="sources">
                <section>
                  <Stack
                    alignItems="flex-start"
                    justifyContent="space-between"
                    direction="row"
                  >
                    <div>
                      <Typography variant="h6" component="h3">
                        <FormattedMessage
                          id="mss.source-audio"
                          defaultMessage="Source Audio"
                        />
                      </Typography>
                      <Typography variant="subtitle1">
                        <FormattedMessage
                          id="mss.source-audio.desc"
                          defaultMessage="(Multiple files possible)"
                        />
                      </Typography>
                    </div>
                    <Stack spacing={1}>
                      <Button
                        variant="outlined"
                        className="btn-audio"
                        color="primary"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={onAllSourceAudioDelete}
                      >
                        <FormattedMessage
                          id="mss.btn.delete-all"
                          defaultMessage="Delete all"
                        />
                      </Button>
                    </Stack>
                  </Stack>

                  {sourceAudios.map((audio, i) => {
                    return (
                      <AudioCard
                        key={audio.id}
                        title={audio.file.name}
                        onDelete={onSourceAudioDelete(i)}
                        audioId={`audio${i}-player`}
                        audioTitle={audio.file.name}
                        audioUrl={audio.file}
                      />
                    );
                  })}

                  <DropzoneContainer
                    successCallback={onSourceAudiosSelect}
                    maxSize={MAX_FILE_SIZE}
                    accept={{
                      'audio/*': [],
                    }}
                  >
                    <p>
                      <UploadFileIcon fontSize="small" />
                      <FormattedMessage
                        id="cvc.dropzone"
                        defaultMessage="Drop files or click here"
                      />
                    </p>
                    <p style={{ marginTop: 0 }}>
                      <AddCircleOutlineIcon fontSize="small" />
                      <FormattedMessage
                        id="cvc.dropzone.multiple"
                        defaultMessage="You can drop multiple files."
                      />
                    </p>
                  </DropzoneContainer>
                </section>
              </div>

              <div className="results">
                <ProcessingButtons
                  isProcessing={isProcessing}
                  isDisabled={
                    sourceAudios.length === 0 ||
                    !selectedModelTypes.find((model) => !!model)
                  }
                  onAbort={abort}
                  onSubmit={onSubmit}
                />
                <section>
                  <ErrorToast
                    isOpen={isError}
                    updateIsOpen={(status: boolean) => setIsError(status)}
                  />
                </section>
                <section className="results-container">
                  {results.length > 0 && (
                    <Stack
                      alignItems="flex-start"
                      justifyContent="space-between"
                      direction="row"
                    >
                      <Tooltip
                        title={'생성한 결과물을 표시합니다.'}
                        arrow
                        placement="bottom-start"
                      >
                        <Typography variant="h6" component="h3">
                          <FormattedMessage
                            id="mss.results"
                            defaultMessage="RESULTS"
                          />
                        </Typography>
                      </Tooltip>
                      <Button
                        variant="outlined"
                        className="btn-audio"
                        color="primary"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() => setResults([])}
                      >
                        <FormattedMessage
                          id="cvc.btn.delete-all"
                          defaultMessage="Delete all"
                        />
                      </Button>
                    </Stack>
                  )}
                  {results.map((r, i) => {
                    const name = r.name;

                    return (
                      <AudioCard
                        key={r.id}
                        title={name}
                        onDelete={onResultDelete(i)}
                        audioId={`result${r.id}-player`}
                        audioTitle={name}
                        audioUrl={r.audioData}
                      ></AudioCard>
                    );
                  })}
                </section>
              </div>
              <ReportButton submitCallback={handleReportSubmit} />
            </WaveformProvider>
          </div>
        </Area>
      </Row>
    </AreaGrid>
  );
};

export default MssPage;
