import { Fragment } from 'react';
import { FileWithPath } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import {
  Delete as DeleteIcon,
  UploadFile as UploadFileIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from '@mui/icons-material';

import DropzoneContainer from '../common/DropzoneContainer';
import RecordButton from '../common/RecordButton';
import { AudioData } from '../common/types';
import AudioCard from '../common/AudioCard';
import {
  convertAudioBufferToWavBlob,
  convertBlobToAudioBuffer,
  convertStereoToMono,
  getAudioContext,
} from '../util/audio';

type TargetType = 'A' | 'B';

interface Props {
  isInterpolation: boolean;
  targetAudios: AudioData[];
  updateTargetAudios: (audios: AudioData[]) => void;
  targetAudios2?: AudioData[];
  updatetargetAudios2?: (audios: AudioData[]) => void;
}

const TargetContainer = ({
  isInterpolation,
  targetAudios,
  updateTargetAudios,
  targetAudios2,
  updatetargetAudios2,
}: Props) => {
  // 녹음 완료 시
  const onRecordEnd = (type?: TargetType) => async (blob: Blob) => {
    const audioContext = getAudioContext();

    // Convert the blob to mono.
    const audioBuffer = await convertBlobToAudioBuffer(audioContext, blob);
    const monoBuffer = convertStereoToMono(audioContext, audioBuffer);
    const monoBlob = await convertAudioBufferToWavBlob(monoBuffer);

    const file: FileWithPath = new File(
      [monoBlob],
      `Recorded_audio_${Date.now()}.wav`,
      {
        lastModified: Date.now(),
      }
    );
    if (type === 'B') {
      if (!targetAudios2 || !updatetargetAudios2) return;
      updatetargetAudios2?.([
        ...targetAudios2,
        {
          file,
          type: 'recorded',
          name: 'Recorded audio',
        },
      ]);
    } else {
      updateTargetAudios([
        ...targetAudios,
        {
          file,
          type: 'recorded',
          name: 'Recorded audio',
        },
      ]);
    }
  };

  // 타겟 오디오 파일 전체 삭제 시
  const onAllTargetAudioDelete = (type?: TargetType) => {
    if (type === 'B') {
      if (!targetAudios2 || !updatetargetAudios2) return;
      updatetargetAudios2?.([]);
    } else {
      updateTargetAudios([]);
    }
  };

  // 타겟 오디오 파일 삭제 시
  const onTargetAudioDelete = (index: number, type?: TargetType) => () => {
    if (type === 'B') {
      if (!targetAudios2 || !updatetargetAudios2) return;
      const files = targetAudios2.filter((_, i) => i !== index);
      updatetargetAudios2(files);
      return;
    } else {
      const files = targetAudios.filter((_, i) => i !== index);
      updateTargetAudios(files);
    }
  };

  // 타겟 오디오 파일 선택 시 (복수 선택 가능)
  const onTargetAudiosSelect = async (
    files: FileWithPath[],
    type?: TargetType
  ) => {
    const audioContext = getAudioContext();

    // Convert the files to AudioBuffers.
    const audioBuffers = await Promise.all(
      files.map((file) => convertBlobToAudioBuffer(audioContext, file))
    );

    // Convert to mono.
    const monoBuffers = audioBuffers.map((buffer) =>
      convertStereoToMono(audioContext, buffer)
    );

    // Convert back to WAV blobs.
    const blobs = await Promise.all(
      monoBuffers.map(convertAudioBufferToWavBlob)
    );

    const list: AudioData[] = files.map((f, i) => ({
      file: new File([blobs[i]], f.name, { type: 'audio/wav' }),
      type: 'file',
      name: f.name,
    }));

    if (type === 'B') {
      if (!targetAudios2 || !updatetargetAudios2) return;
      updatetargetAudios2([...targetAudios2, ...list]);
      return;
    } else {
      updateTargetAudios([...targetAudios, ...list]);
    }
  };

  const hasRecordedTarget = (type: TargetType) => {
    if (type === 'B') {
      if (!targetAudios2) return false;
      return (
        targetAudios2.filter((audio) => audio.type === 'recorded').length > 0
      );
    } else {
      return (
        targetAudios.filter((audio) => audio.type === 'recorded').length > 0
      );
    }
  };

  return (
    <section>
      {[targetAudios, targetAudios2].map(
        (target: AudioData[] | undefined, idx) => {
          if ((!isInterpolation && idx === 1) || !target) return null;
          else {
            const type = idx === 1 ? 'B' : 'A';
            const hasRecorded = hasRecordedTarget(type);
            return (
              <Fragment key={type}>
                <Stack
                  alignItems="flex-start"
                  justifyContent="space-between"
                  direction="row"
                  sx={(idx === 1 && { mt: 2 }) || {}}
                >
                  <div>
                    <Typography variant="h6" component="h3">
                      {`Target Voice ${isInterpolation ? type : ''}`}
                    </Typography>
                    <Typography variant="subtitle1">
                      <FormattedMessage
                        id="cvc.target-audio.desc"
                        defaultMessage="(Multiple files possible)"
                      />
                    </Typography>
                  </div>
                  <Stack spacing={1}>
                    <RecordButton
                      className="small"
                      onRecordEnd={onRecordEnd(type)}
                      disabled={target.length > 0}
                    />
                    <Button
                      variant="outlined"
                      className="btn-audio"
                      color="primary"
                      size="small"
                      startIcon={<DeleteIcon />}
                      onClick={() => onAllTargetAudioDelete(type)}
                    >
                      <FormattedMessage
                        id="cvc.btn.delete-all"
                        defaultMessage="Delete all"
                      />
                    </Button>
                  </Stack>
                </Stack>

                {target.map((audio, i) => {
                  return (
                    <AudioCard
                      key={audio.file.name}
                      title={audio.file.name}
                      onDelete={onTargetAudioDelete(i, type)}
                      audioId={`target${i}-player`}
                      audioTitle={audio.file.name}
                      audioUrl={audio.file}
                    />
                  );
                })}
                {!hasRecorded && (
                  <Tooltip
                    title={
                      <span style={{ whiteSpace: 'pre-line' }}>
                        {
                          '대상이 되는 목소리에 공백 구간이 있으면 \n 자동으로 제외하고 음색을 추출합니다.'
                        }
                      </span>
                    }
                    arrow
                    placement="top"
                  >
                    <div>
                      <DropzoneContainer
                        successCallback={(filed: FileWithPath[]) =>
                          onTargetAudiosSelect(filed, type)
                        }
                      >
                        <p>
                          <UploadFileIcon fontSize="small" />
                          <FormattedMessage
                            id="cvc.dropzone"
                            defaultMessage="Drop files or click here"
                          />
                        </p>
                        <p style={{ marginTop: 0 }}>
                          <AddCircleOutlineIcon fontSize="small" />
                          <FormattedMessage
                            id="cvc.dropzone.multiple"
                            defaultMessage="You can drop multiple files."
                          />
                        </p>
                      </DropzoneContainer>
                    </div>
                  </Tooltip>
                )}
              </Fragment>
            );
          }
        }
      )}
    </section>
  );
};
export default TargetContainer;
