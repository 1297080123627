import React from 'react';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { DEFAULT_ERROR_MESSAGE } from '../consts';

interface Props {
  isOpen: boolean;
  updateIsOpen: (isOpen: boolean) => void;
  message?: string;
}
const ErrorToast = ({ isOpen, updateIsOpen, message }: Props) => {
  return isOpen ? (
    <Alert
      severity="error"
      sx={{ marginTop: '0.5rem', whiteSpace: 'pre-wrap' }}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            updateIsOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      }
    >
      <AlertTitle>Error</AlertTitle>
      <p>{message ? message : DEFAULT_ERROR_MESSAGE}</p>
    </Alert>
  ) : null;
};

export default ErrorToast;
