export type ParameterDefinitions = typeof SLIDER_PARAMETERS;
export type Parameter<T extends keyof ParameterDefinitions> =
  ParameterDefinitions[T];

export const SLIDER_PARAMETERS = {
  semitone: {
    name: 'semitone',
    label: 'Pitch Shift',
    tooltip: '반음 단위로 수정',
    defaultValue: 0,
    minValue: -24,
    maxValue: 24,
    step: 1,
    minLabel: 'min',
    maxLabel: 'max',
  },
  pitch: {
    name: 'pitch_variance',
    label: 'Pitch Variance',
    tooltip: '음정 편차 조절',
    defaultValue: 1,
    minValue: 0,
    maxValue: 2,
    step: 0.1,
    minLabel: 'static',
    maxLabel: 'dynamic',
  },
  speed: {
    name: 'scale_factor',
    label: 'Tempo Change',
    tooltip: '템포 조절',
    defaultValue: 1,
    minValue: 0.5,
    maxValue: 2,
    step: 0.1,
    minLabel: 'slow',
    maxLabel: 'fast',
  },
  similarity: {
    name: 'similarity',
    label: 'Target Similarity',
    tooltip: '타겟 유사도, 낮을수록 결과물이 랜덤하게 변합니다.',
    defaultValue: 2,
    minValue: 1,
    maxValue: 5,
    step: 0.1,
    minLabel: 'min',
    maxLabel: 'max',
  },
};
