import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import WaveSurfer from 'wavesurfer.js';
import { Button, IconButton, Slider, Stack } from '@mui/material';
import {
  Download as DownloadIcon,
  PlayArrow as PlayCircleIcon,
  Pause as PauseCircleIcon,
  VolumeUp as VolumeUpIcon,
  VolumeOff as VolumeOffIcon,
} from '@mui/icons-material';

import './waveform.scss';
import { WaveformContext } from './WaveformContext';
import {
  getFileExtension,
  removeInvalidFileNameChars,
} from '../../util/string';

interface Props {
  url: string;
  name: string;
}

const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const Waveform = ({ url, name }: Props) => {
  const { addWaveform, removeWaveform, pauseOtherWaveforms } =
    useContext(WaveformContext);
  const [waveSurfer, setWaveSurfer] = useState<WaveSurfer | null>(null);
  const waveformRef = useRef<HTMLDivElement>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<string>(formatTime(0));
  const [duration, setDuration] = useState<string>(formatTime(0));
  const [volume, setVolume] = useState<number>(1);
  const [isMuted, setIsMuted] = useState<boolean>(false);

  useEffect(() => {
    if (!url || !waveformRef.current) return;

    const ws = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: '#dee2e6',
      progressColor: '#ffb100',
      cursorColor: '#ffb100',
      barWidth: 1,
      barRadius: 1,
      responsive: true,
      height: 40,
    });

    setWaveSurfer(ws);
    addWaveform(ws);

    ws.load(url);

    ws.on('ready', () => {
      setIsReady(true);
      const formattedDuration = formatTime(ws?.getDuration()!);
      setDuration(formattedDuration);
    });

    ws.on('audioprocess', () => {
      const formattedTime = formatTime(ws?.getCurrentTime()!);
      setCurrentTime(formattedTime);
    });

    ws.on('seek', () => {
      pauseOtherWaveforms();
      ws?.play();
      setIsPlaying(true);
    });

    ws.on('finish', () => {
      setIsPlaying(false);
    });

    ws.on('pause', () => {
      setIsPlaying(false);
    });

    return () => {
      removeWaveform(ws);
      ws.destroy();
    };
  }, [url, addWaveform, removeWaveform, pauseOtherWaveforms]);

  const handlePlay = useCallback(() => {
    if (!isReady || !waveSurfer) return;
    if (isPlaying) {
      waveSurfer.pause();
    } else {
      pauseOtherWaveforms();
      waveSurfer.play();
    }
    setIsPlaying(!isPlaying);
  }, [isReady, isPlaying, waveSurfer, pauseOtherWaveforms]);

  const handleMute = useCallback(() => {
    if (!isReady) return;
    if (isMuted) {
      waveSurfer?.setVolume(volume);
    } else {
      waveSurfer?.setVolume(0);
    }
    setIsMuted(!isMuted);
  }, [isReady, isMuted, volume, waveSurfer]);

  const handleVolumeChange = useCallback(
    (_: any, newValue: number | number[]) => {
      if (!isReady) return;
      waveSurfer?.setVolume(newValue as number);
      setVolume(newValue as number);
    },
    [isReady, waveSurfer]
  );

  // Remove invalid characters from file name,
  // and add .wav extension if not present.
  const fileName = useMemo(() => {
    const validName = removeInvalidFileNameChars(name);
    const ext = getFileExtension(validName);

    return ext === 'wav' ? validName : `${validName}.wav`;
  }, [name]);

  return (
    <div className="wavesurfer">
      <Stack
        direction="row"
        justifyContent="flex-end"
        className="wavesurfer-wrapper"
      >
        <div ref={waveformRef} className="wavesurfer-waveform" />
        <IconButton
          size="small"
          onClick={handlePlay}
          className="wavesurfer-play"
        >
          {isPlaying ? (
            <PauseCircleIcon fontSize="medium" />
          ) : (
            <PlayCircleIcon fontSize="medium" />
          )}
        </IconButton>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="wavesurfer-controls"
          sx={{ width: 'calc(100% - 1.5rem)' }}
          flexWrap="wrap"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={1.5}
          >
            <div className="wavesurfer-time">
              <span>
                {currentTime} / {duration}
              </span>
            </div>
            <Stack
              className="wavesurfer-volume"
              spacing={1}
              direction="row"
              sx={{ width: 100 }}
              alignItems="center"
            >
              <IconButton size="small" onClick={handleMute}>
                {isMuted ? (
                  <VolumeOffIcon fontSize="small" />
                ) : (
                  <VolumeUpIcon fontSize="small" />
                )}
              </IconButton>
              <Slider
                size="small"
                aria-label="Volume"
                value={volume}
                min={0}
                max={1}
                step={0.01}
                onChange={handleVolumeChange}
              />
            </Stack>
          </Stack>
          <Button
            variant="contained"
            size="small"
            href={url}
            download={fileName}
            className="wavesurfer-download"
            startIcon={<DownloadIcon fontSize="medium" />}
          >
            Download
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

export default Waveform;
