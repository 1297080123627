import React, { PropsWithChildren } from 'react';
import { AreaWidthChange, Direction, SplitterProperties } from '../types';

import Splitter from './Splitter';

interface Props {
  index?: number;
  onAreaWidthChange?: (change: AreaWidthChange) => void;
  onAreaWidthUpdate?: (update: AreaWidthChange) => void;
  name: string;
  span?: number;
  size?: string;
  minSize?: string;
  maxSize?: string;
  collapsable?: boolean;
  splitterAt?: Direction | Direction[];
  splitters?: SplitterProperties[];
}

const Area = ({ name, splitters = [], children }: PropsWithChildren<Props>) => {
  const id = `area-${name}`;

  return (
    <div
      id={id}
      className="area"
      style={{
        gridArea: name,
      }}
    >
      {children}
      {splitters.map((s, i) => {
        return (
          <Splitter
            key={i}
            containerIndex={s.containerIndex}
            containerSize={s.containerSize}
            at={s.at}
            onChange={s.onChange}
            onUpdate={s.onUpdate}
          />
        );
      })}
    </div>
  );
};

export default Area;
