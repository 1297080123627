import { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TextField,
} from '@mui/material';
import {
  Clear as ClearIcon,
  AudioFile as AudiofileIcon,
} from '@mui/icons-material';
import { FileWithPath } from 'react-dropzone';
import Waveform from './waveform/Waveform';

interface Props {
  title: string;
  onDelete: () => void;
  audioId: string;
  audioTitle: string;
  audioUrl: FileWithPath | Blob;
  children?: React.ReactNode;
}

const AudioCard = ({
  title,
  onDelete,
  audioId,
  audioTitle,
  audioUrl,
  children,
}: Props) => {
  const [inputText, setInputText] = useState<string>(audioTitle || title);
  const [fileName, setFileName] = useState<string>(inputText);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const url = useMemo(() => URL.createObjectURL(audioUrl), [audioUrl]);

  useEffect(() => {
    if (isDisabled) return;
    inputRef.current?.focus();
  }, [isDisabled]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      setIsDisabled(true);
      setFileName(inputText);
      inputRef.current?.blur();
    } else if (e.key === 'Escape') {
      setInputText(fileName);
      setIsDisabled(true);
      inputRef.current?.blur();
    }
  };

  return (
    <Card
      sx={{
        margin: '0.5rem 0',
        padding: '12px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 2px',
        '.MuiCardHeader-root': {
          padding: '0px 0px 0.5rem 0px',
        },
        '.MuiCardHeader-title': {
          fontSize: '0.9rem',
          wordBreak: 'break-all',
        },
        '.MuiCardContent-root': { paddingBottom: '0px' },
        '.MuiCardContent-root:last-child': { paddingBottom: '0px' },
      }}
    >
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={onDelete}
            size="small"
            sx={{ boxShadow: 'none', marginRight: '2px' }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        }
      />
      <CardContent
        sx={{
          margin: '-2.5rem 0 0.5rem -1rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <AudiofileIcon
          fontSize="small"
          sx={{ color: 'action.active', mr: 0.8, my: 0.5 }}
        />
        <TextField
          fullWidth
          multiline
          inputRef={inputRef}
          disabled={isDisabled}
          value={inputText}
          variant="standard"
          onDoubleClick={() => setIsDisabled(false)}
          onChange={(e) => setInputText(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={() => {
            setFileName(inputText);
            setIsDisabled(true);
          }}
          sx={{
            '.MuiInputBase-inputMultiline': {
              fontSize: '0.8rem',
            },
          }}
        />
      </CardContent>
      <CardContent sx={{ padding: 0 }}>
        <Waveform name={fileName} url={url} />
      </CardContent>
      {children ? <CardContent>{children}</CardContent> : null}
    </Card>
  );
};

export default AudioCard;
