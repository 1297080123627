import styled from '@emotion/styled';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  PlayCircle as PlayCircleIcon,
  PauseCircle as PauseCircleIcon,
} from '@mui/icons-material';
import { EvaluationAudioContext } from './EvaluationAudioContext';

const StyledWrapper = styled('div')({
  paddingTop: '20px',

  '& .MuiAccordionSummary-content': {
    display: 'flex',

    '& .audio-set-info': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',

      '& .audio-set': {
        flex: '0 0 auto',
      },

      '& .step': {
        flex: '1',
        textAlign: 'right',
        fontSize: '14px',
        color: 'white',
      },
    },
  },
  '& .MuiAccordionSummary-root': {
    backgroundColor: '#bfd3dc',
    borderRadius: '4px 4px 0 0',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    paddingLeft: '2rem',
    transition: 'padding 0s',

    '&.Mui-expanded': {
      paddingRight: '2rem',
      paddingLeft: 0,
    },
  },
  '& .MuiAccordionDetails-root': {
    maxHeight: '320px',
    overflowY: 'auto',
  },

  '& .audio-container-grid': {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(265px, 1fr))',
    gap: '16px',
  },

  '& .audio-item': {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#fff',
    padding: '1rem',
    borderRadius: '6px',
    marginTop: ' 0.3rem',
    marginBottom: '0.3rem',
    boxShadow: '1px 1px 10px 0 rgba(0, 0, 0, 0.2)',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '& span': {
      fontSize: '0.9rem',
      flex: '10 1',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textAlign: 'left',
      width: '80%',
    },
  },

  '& .audio-button': {
    width: '30px',
    minWidth: '30px',
    height: '30px',
    lineHeight: '30px',
    borderRadius: '50%',
    color: '#297579',
  },
});

export type AudioData = {
  name: string;
  path: string;
};

const CheckpointAccordion = ({
  audioSet,
  audioData,
  step,
  showAccordionName,
}: {
  audioSet: string;
  audioData: AudioData[];
  step: number;
  showAccordionName: boolean;
}) => {
  const [currentAudioIndex, setCurrentAudioIndex] = useState<number>(-1);
  const voicePlayers = useRef<Array<HTMLAudioElement | null>>([]);
  const { currentAudioIndices, playNewAudio, stopAudio } = useContext(
    EvaluationAudioContext
  );

  useEffect(() => {
    //If the audio to be played is in this audioSet
    if (Object.keys(currentAudioIndices).includes(audioSet)) {
      playAudio(currentAudioIndices[audioSet]);
      setCurrentAudioIndex(currentAudioIndices[audioSet]);
    }
    // Forcefully terminating the audio play
    if (Object.keys(currentAudioIndices).length === 0) {
      pauseAudio(currentAudioIndex);
    }
  }, [currentAudioIndices]);

  const playAudio = (index: number) => {
    const audio = voicePlayers.current[index];

    if (audio) {
      const { path } = audioData[index];

      // Set the new source
      audio.src = path;

      // Add an event listener for canplaythrough
      audio.addEventListener('canplaythrough', () => {
        // Play the audio after the source has been loaded
        audio.play();
      });

      // Load the new source
      audio.load();
    }
  };

  const pauseAudio = (index: number) => {
    voicePlayers.current[index]?.pause();
    setCurrentAudioIndex(-1);
  };

  const onPlay = (index: number) => {
    playNewAudio({ [audioSet]: index });
  };

  const onStop = () => {
    stopAudio();
  };

  const memoizedAudioItems = useMemo(() => {
    return audioData.map((data, index) => {
      const isCurrentAudio = currentAudioIndex === index;

      return (
        <div key={index} className="audio-item">
          <span>{data.name}</span>
          <Button
            variant="text"
            className="audio-button"
            onClick={() => (isCurrentAudio ? onStop() : onPlay(index))}
          >
            {isCurrentAudio ? <PauseCircleIcon /> : <PlayCircleIcon />}
          </Button>
          <audio
            ref={(el) => (voicePlayers.current[index] = el)}
            onEnded={() => onStop()}
          >
            {isCurrentAudio &&
              voicePlayers.current[index]?.src !== data.path && (
                <source key={data.path} src={data.path} />
              )}
          </audio>
        </div>
      );
    });
  }, [audioData, currentAudioIndex, onStop, onPlay]);

  return (
    <StyledWrapper>
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="audio-set-info">
            <Typography className="audio-set">{audioSet}</Typography>
            {showAccordionName && (
              <Typography className="step">{`${step}-iterations`}</Typography>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="audio-container-grid">{memoizedAudioItems}</div>
        </AccordionDetails>
      </Accordion>
    </StyledWrapper>
  );
};

export default CheckpointAccordion;
