import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import useAuth from '../auth/useAuth';

const IndexPage = () => {
  const { user, isLoading, error } = useAuth0();

  const { isInitializing, permissions } = useAuth();

  const indexPath = useMemo(() => {
    if (isLoading || isInitializing) {
      return '/';
    }

    if (error) {
      return '/message/unknown-error';
    } else if (permissions && permissions.length > 0) {
      return permissions[0];
    } else {
      return user ? '/message/needs-approval' : 'tts';
    }
  }, [user, isLoading, error, isInitializing, permissions]);

  return isLoading ? (
    <div className="container signin">
      <div>
        <div className="title">Supertone Studio</div>
        <div>
          <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to={indexPath} replace={true} />
  );
};

export default IndexPage;
