import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { recordAudio, Recorder } from '../util/audio';
import { Button } from '@mui/material';
import {
  FiberManualRecord as FiberManualRecordIcon,
  Stop as StopIcon,
} from '@mui/icons-material';

interface Props {
  className?: string;
  onRecordEnd: (blob: Blob) => void;
  disabled?: boolean;
}

const RecordButton = ({
  className,
  onRecordEnd,
  disabled = false,
  ...rest
}: Props) => {
  const [recorder, setRecorder] = useState<Recorder | null>(null);

  const onClick = async () => {
    if (!recorder) {
      setRecorder(await recordAudio());
    } else {
      onRecordEnd(await recorder.stop());
      setRecorder(null);
    }
  };

  useEffect(() => {
    if (recorder) recorder.start();
  }, [recorder]);

  const label = recorder ? (
    <FormattedMessage id="cvc.btn.stop" defaultMessage="Stop" />
  ) : (
    <FormattedMessage id="cvc.btn.record" defaultMessage="Record" />
  );

  return (
    <Button
      className="btn-audio"
      size="small"
      variant="outlined"
      color={!recorder ? 'error' : 'warning'}
      startIcon={!recorder ? <FiberManualRecordIcon /> : <StopIcon />}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default RecordButton;
