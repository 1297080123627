import React from 'react';

const NeedsApproval = () => (
  <div>
    관리자의 승인이 필요합니다.
    <br />
    수퍼톤에 문의해주세요.
  </div>
);

export default NeedsApproval;
