import { useCallback, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { FormattedMessage, useIntl } from 'react-intl';
import cuid from 'cuid';
import toast from 'react-hot-toast';
import {
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  UploadFile as UploadFileIcon,
  Delete as DeleteIcon,
  Clear as ClearIcon,
  MusicNote as MusicNoteIcon,
} from '@mui/icons-material';

import {
  getParameters,
  Parameter,
  ParameterDefinitions,
  ParameterType,
} from './rules';
import SliderParameter from '../common/parameter/SliderParameter';
import CheckboxParameter from '../common/parameter/CheckboxParameter';
import { formatReportData, sendPostRequest, sendReport } from '../util/api';
import { addSuffixToFileName, removeFileSuffix } from '../util/string';
import AudioCard from '../common/AudioCard';
import ErrorToast from '../common/ErrorToast';
import DropzoneContainer from '../common/DropzoneContainer';
import TargetContainer from '../components/TargetContainer';
import ReportButton, { ReportData } from '../common/ReportButton';
import { WaveformProvider } from '../common/waveform/WaveformContext';
import AreaGrid from '../common/area-grid/AreaGrid';
import Row from '../common/area-grid/Row';
import Area from '../common/area-grid/Area';
import ProcessingButtons from '../components/ProcessingButtons';
import { AudioData } from '../common/types';
import { MODEL_NAME, MODEL_ORDER_MAP } from './const';
import { MAX_FILE_SIZE } from '../consts';

interface AudioResult {
  id: string;
  date: Date;
  audioData: Blob;
  parameterValues: Partial<
    Record<
      keyof ParameterDefinitions,
      | ParameterDefinitions[keyof ParameterDefinitions]['defaultValue']
      | undefined
    >
  >;
  name: string;
}

const MODEL_TYPES = [
  'sansy-v1',
  'sansy-v1-velocity',
  'sansy-v1-stable',
  'sansy-v1-stable-thecross',
  'sansy-v1-stable-supergirls',
  'sansy-v1-stable-score-analysis',
];

export type ModelType = typeof MODEL_TYPES[number];

const API_PATH_SVS = process.env.REACT_APP_API_PATH_SVS!;

const SvsPage = () => {
  const { formatMessage } = useIntl();

  // 소스 미디
  const [sourceMidi, setSourceMidi] = useState<File | null>(null);

  // 타겟 오디오
  const [targetAudios, setTargetAudios] = useState<AudioData[]>([]);

  // 권한에 따른 모델 타입 목록
  const [modelTypes, setModelTypes] = useState<ModelType[]>(MODEL_TYPES);

  // 모델 타입
  const [modelType, setModelType] = useState<ModelType>(MODEL_TYPES[0]);

  // 파라미터 값
  const [parameterValues, setParameterValues] = useState<
    Partial<
      Record<
        keyof ParameterDefinitions,
        | ParameterDefinitions[keyof ParameterDefinitions]['defaultValue']
        | undefined
      >
    >
  >({});

  // 파라미터 종류
  const [parameters, setParameters] = useState<
    Parameter<keyof ParameterDefinitions>[]
  >([]);

  // 오디오 파라미터
  const [scoreAudio, setScoreAudio] = useState<AudioData | null>(null);

  // 결과 처리 중 여부
  const [isProcessing, setIsProcessing] = useState(false);

  // 오류 발생 여부
  const [isError, setIsError] = useState(false);

  // result index 관리
  const [resultIndex, setResultIndex] = useState(1);

  // 결과
  const [results, setResults] = useState<AudioResult[]>([]);

  // 소스 미디 파일 선택 시
  const onSourceMidiSelect = async (acceptedFiles: FileWithPath[]) => {
    const acceptedFile = acceptedFiles[0];

    setSourceMidi(acceptedFile);
  };

  // 소스 미디 파일 삭제 시
  const onSourceMidiDelete = () => setSourceMidi(null);

  // 모델 타입 선택 시
  const onModelTypeChange = (e: SelectChangeEvent<ModelType>) => {
    const modelType = e.target.value as ModelType;

    setModelType(modelType);
  };

  // 모델 타입 변경 시 파라미터 변경
  useEffect(() => {
    if (!modelType) return;

    setParameters(getParameters(modelType));
    setScoreAudio(null);
    setSourceMidi(null);
    setTargetAudios([]);
    setResults([]);
    setResultIndex(1);
  }, [modelType]);

  // 파라미터 변경 시 초기값 세팅
  useEffect(() => {
    const values = parameters.reduce<
      Record<string, string | number | boolean | AudioData>
    >((acc, p) => {
      acc[p.name] = p.defaultValue;
      return acc;
    }, {});

    setParameterValues(values);
  }, [parameters, setParameterValues]);

  // 소스 오디오, 타겟 오디오 변경 시 resultIndex 초기화
  useEffect(() => {
    setResultIndex(1);
  }, [sourceMidi, targetAudios]);

  // 파라미터 토글 시
  const onParameterToggle = useCallback(
    <T extends keyof ParameterDefinitions>(parameter: Parameter<T>) =>
      () => {
        const value = parameterValues[parameter.name];
        const newValue = !value;

        setParameterValues({
          ...parameterValues,
          [parameter.name]: newValue,
        });
      },
    [parameterValues, setParameterValues]
  );

  // 파라미터 값 변경 시
  const onParameterValueChange = useCallback(
    <T extends keyof ParameterDefinitions>(name: T, value?: number) => {
      if (value === undefined) {
        const values = { ...parameterValues };
        delete values[name];

        setParameterValues(values);
      } else {
        setParameterValues({
          ...parameterValues,
          [name]: value,
        });
      }
    },
    [parameterValues, setParameterValues]
  );

  const onAudioSelect = async (files: FileWithPath[]) => {
    const file = files[0];

    setScoreAudio({
      file: new File([file], file.name, { type: 'audio/wav' }),
      type: 'file',
      name: file.name,
    });
  };

  const onAudioDelete = () => {
    setScoreAudio(null);
  };

  useEffect(() => {
    if (scoreAudio) {
      const url = URL.createObjectURL(scoreAudio.file);
      const player = document.getElementById(
        'audio-player'
      ) as HTMLAudioElement;

      // 오디오 플레이어 설정
      if (player) player.src = url;
    }
  }, [scoreAudio]);

  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const getRequestData = async () => {
    const data: { [key: string]: unknown } = {
      api: 'svs',
      model_type: modelType,
      tgt: targetAudios,
      key_transpose: parameterValues['key_transpose'],
      pitch_variance: parameterValues['pitch_variance'],
      variation_takes: parameterValues['variation_takes'],
      confident_range_shift: parameterValues['confident_range_shift'],
      legato_limit: parameterValues['legato_limit'],
      padding: parameterValues['padding'],
      use_midi_velocity: parameterValues['use_midi_velocity'],
      score_audio: scoreAudio?.file ?? {},
    };

    if (sourceMidi) {
      data['midi'] = sourceMidi;
    }

    return data;
  };

  const onSubmit = async () => {
    if (!modelType) return;

    if (!scoreAudio && !sourceMidi) {
      toast.error('Please select a score audio or source midi.');
      return;
    }

    if (targetAudios.length === 0) {
      toast.error(
        formatMessage({
          id: 'svs.msg.target-audio-needed',
          defaultMessage: 'Please select a target voice.',
        })
      );
      return;
    }

    // 녹음 된 파일은 단일로만 존재해야 하고, 일반 파일과 섞일 수 없음
    if (
      targetAudios.length > 1 &&
      targetAudios.filter((audio) => audio.type === 'recorded').length > 0
    ) {
      toast.error(
        formatMessage({
          id: 'svs.msg.too-many-recorded-target-audio',
          defaultMessage: 'Recorded target voice should exist by itself.',
        })
      );
      return;
    }

    setIsProcessing(true);

    try {
      let targetName = targetAudios.length > 0 ? targetAudios[0].name : '';

      const data = await getRequestData();
      const abortController = new AbortController();
      setAbortController(abortController);

      const r = await sendPostRequest(
        API_PATH_SVS,
        data,
        {
          'Content-Type': 'multipart/form-data',
        },
        {
          responseType: 'arraybuffer',
        },
        abortController
      );

      setIsError(false);

      const name = scoreAudio
        ? `${removeFileSuffix(scoreAudio.name)}_result_${resultIndex}.wav`
        : sourceMidi
        ? targetName
          ? removeFileSuffix(
              addSuffixToFileName(
                sourceMidi.name,
                `_to_${removeFileSuffix(targetName)}`
              )
            ) + `_${resultIndex}.wav`
          : `${removeFileSuffix(sourceMidi.name)}_result_${resultIndex}.wav`
        : 'default_result.wav';

      setResultIndex(resultIndex + 1);

      const result: AudioResult = {
        id: cuid(),
        date: new Date(),
        audioData: new Blob([r.data], { type: 'audio/wav' }),
        parameterValues: { ...parameterValues },
        name,
      };
      setResults([result, ...results]);
    } catch (e: any) {
      if (e?.code === 'ERR_CANCELED') {
        return e.code;
      }
      setIsError(true);
      console.error(e);
    } finally {
      setIsProcessing(false);
    }
  };

  // 결과 삭제 시
  const onResultDelete = (index: number) => () => {
    const files = results.filter((_, i) => i !== index);
    setResults(files);
  };

  // 결과에 사용된 파라미터를 적용
  const onApplyClick = (r: AudioResult) => () => {
    setParameterValues(r.parameterValues);
  };

  // 결과 오디오 파일 전체 삭제 시
  const onAllResultAudioDelete = () => {
    setResultIndex(1);
    setResults([]);
  };

  const handleReportSubmit = async ({ user, problem }: ReportData) => {
    const inputData = await getRequestData();
    if (!inputData) return;

    // inputData가 있는 경우, inputData.src를 배열로 변환
    if (sourceMidi) {
      inputData.src = [sourceMidi];
    }

    if (targetAudios.length > 0) {
      inputData.tgt = [...targetAudios];
    }

    const data = formatReportData({
      ...inputData,
      user,
      problem,
    });

    const r = await sendReport(data);
    return r;
  };

  const isSourceFileSelected = !!sourceMidi;

  const hasRecordedTarget =
    targetAudios.filter((audio) => audio.type === 'recorded').length > 0;

  const abort = useCallback(() => {
    abortController?.abort();
  }, [abortController]);

  return (
    <AreaGrid className="svs">
      <Row>
        <Area
          name="main"
          size="350px"
          minSize="280px"
          maxSize="40%"
          splitterAt="right"
        >
          {modelTypes.length && modelType && (
            <aside className="contents sidebar">
              <section className="description">
                <Typography variant="h2" component="h2">
                  Singing Voice Synthesis
                </Typography>
                <Typography variant="body1">
                  미디 파일을 타겟 음성으로 합성하여 가창 음원을 생성합니다.
                </Typography>
              </section>

              <div className="parameter select">
                <Stack
                  justifyContent="flex-start"
                  flexDirection="row"
                  className="select-wrapper"
                >
                  <FormControl fullWidth>
                    <InputLabel>
                      <FormattedMessage
                        id="svs.model-type"
                        defaultMessage="Model type"
                      />
                    </InputLabel>
                    <Select
                      className="svs-select"
                      value={modelType}
                      onChange={onModelTypeChange}
                      fullWidth={true}
                      label={
                        <FormattedMessage
                          id="svs.model-type"
                          defaultMessage="Model type"
                        />
                      }
                    >
                      {modelTypes.map((type) => {
                        return (
                          <MenuItem key={type} value={type}>
                            {MODEL_NAME[type]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
              </div>

              <Divider />

              <section>
                {parameters.map((p) => {
                  const key = p.name;

                  switch (p.type) {
                    case ParameterType.Slider: {
                      return (
                        <SliderParameter<ParameterDefinitions>
                          key={key}
                          name={p.name}
                          label={p.label}
                          tooltip={p.tooltip}
                          defaultValue={p.defaultValue}
                          value={parameterValues[p.name] as number | undefined}
                          minValue={p.minValue}
                          maxValue={p.maxValue}
                          minLabel={'minLabel' in p ? p.minLabel : undefined}
                          maxLabel={'maxLabel' in p ? p.maxLabel : undefined}
                          step={p.step}
                          onChange={onParameterValueChange}
                          onToggle={onParameterValueChange}
                        />
                      );
                    }

                    case ParameterType.Checkbox: {
                      return (
                        <CheckboxParameter<ParameterDefinitions>
                          key={key}
                          name={p.name}
                          label={p.label}
                          tooltip={p.tooltip}
                          defaultValue={p.defaultValue}
                          value={!!parameterValues[p.name]}
                          onChange={onParameterToggle(p)}
                        />
                      );
                    }

                    default:
                      return null;
                  }
                })}
              </section>
            </aside>
          )}
        </Area>
        <Area name="contents">
          <div className="main">
            <WaveformProvider>
              <div className="sources">
                {modelType === 'sansy-v1-stable-score-analysis' && (
                  <section>
                    <Stack
                      alignItems="flex-start"
                      justifyContent="space-between"
                      direction="row"
                    >
                      <Typography variant="h6" component="h3">
                        <FormattedMessage
                          id="svs.score-audio"
                          defaultMessage="Score Audio"
                        />
                      </Typography>
                    </Stack>

                    {scoreAudio && (
                      <AudioCard
                        key={scoreAudio.file.name}
                        title={scoreAudio.file.name}
                        onDelete={onAudioDelete}
                        audioId={`score-audio-player`}
                        audioTitle={scoreAudio.file.name}
                        audioUrl={scoreAudio.file}
                      />
                    )}

                    <DropzoneContainer
                      successCallback={onAudioSelect}
                      maxSize={MAX_FILE_SIZE}
                      accept={{
                        'audio/*': [],
                      }}
                    >
                      <p>
                        <UploadFileIcon fontSize="small" />
                        <FormattedMessage
                          id="dropzone"
                          defaultMessage="Drop files or click here"
                        />
                      </p>
                    </DropzoneContainer>
                  </section>
                )}
                <section>
                  <Stack
                    alignItems="flex-start"
                    justifyContent="space-between"
                    direction="row"
                  >
                    <Typography variant="h6" component="h3">
                      <FormattedMessage
                        id="svs.source-midi"
                        defaultMessage="Source Midi"
                      />
                    </Typography>
                  </Stack>

                  {isSourceFileSelected && (
                    <Card>
                      <CardContent
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingBottom: '16px !important',
                        }}
                      >
                        <MusicNoteIcon
                          fontSize="small"
                          sx={{ color: 'action.active', mr: 0.8, my: 0.5 }}
                        />
                        <span style={{ flexGrow: '1' }}>{sourceMidi.name}</span>
                        <IconButton
                          aria-label="settings"
                          onClick={onSourceMidiDelete}
                          size="small"
                          sx={{ boxShadow: 'none', marginRight: '2px' }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </CardContent>
                    </Card>
                  )}

                  <DropzoneContainer
                    accept={{ 'audio/midi': [], 'audio/x-midi': [] }}
                    successCallback={onSourceMidiSelect}
                    maxSize={MAX_FILE_SIZE}
                  >
                    <p>
                      <UploadFileIcon fontSize="small" />
                      <FormattedMessage
                        id="svs.dropzone"
                        defaultMessage="Drop files or click here"
                      />
                    </p>
                  </DropzoneContainer>
                </section>

                <TargetContainer
                  targetAudios={targetAudios}
                  updateTargetAudios={(d: AudioData[]) => setTargetAudios(d)}
                  isInterpolation={false}
                />
              </div>

              <div className="results">
                <ProcessingButtons
                  isDisabled={
                    (!scoreAudio && !sourceMidi) ||
                    targetAudios.length === 0 ||
                    (targetAudios.length > 1 && hasRecordedTarget)
                  }
                  onAbort={abort}
                  onSubmit={onSubmit}
                  isProcessing={isProcessing}
                />
                <section>
                  <ErrorToast
                    isOpen={isError}
                    updateIsOpen={(status: boolean) => setIsError(status)}
                    message={`에러가 발생했습니다. \n 문제가 반복될 경우 담당자에게
              문의해주시면 신속하게 해결하겠습니다. \n djveem@supertone.ai`}
                  />
                </section>
                <section className="results-container">
                  {results.length > 0 && (
                    <Stack
                      alignItems="flex-start"
                      justifyContent="space-between"
                      direction="row"
                    >
                      <Tooltip
                        title={'생성한 결과물을 표시합니다.'}
                        arrow
                        placement="bottom-start"
                      >
                        <Typography variant="h6" component="h3">
                          <FormattedMessage
                            id="svs.results"
                            defaultMessage="RESULTS"
                          />
                        </Typography>
                      </Tooltip>
                      <Button
                        variant="outlined"
                        className="btn-audio"
                        color="primary"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() => onAllResultAudioDelete()}
                      >
                        <FormattedMessage
                          id="svs.btn.delete-all"
                          defaultMessage="Delete all"
                        />
                      </Button>
                    </Stack>
                  )}
                  {results.map((r, i) => {
                    const name = r.name;

                    return (
                      <AudioCard
                        key={r.id}
                        title={name}
                        onDelete={onResultDelete(i)}
                        audioId={`result${r.id}-player`}
                        audioTitle={name}
                        audioUrl={r.audioData}
                      >
                        <Stack
                          flexDirection="row"
                          justifyContent="flex-start"
                          gap={1}
                        >
                          <Tooltip
                            title={
                              '생성된 결과물의 설정값을 그대로 표시해줍니다.'
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Button
                              variant="outlined"
                              size="small"
                              className="btn-use-source small"
                              color="inherit"
                              onClick={onApplyClick(r)}
                            >
                              <FormattedMessage
                                id="svs.btn.apply-parameters"
                                defaultMessage="Apply parameters"
                              />
                            </Button>
                          </Tooltip>
                        </Stack>
                      </AudioCard>
                    );
                  })}
                </section>
              </div>
              <ReportButton submitCallback={handleReportSubmit} />
            </WaveformProvider>
          </div>
        </Area>
      </Row>
    </AreaGrid>
  );
};

export default SvsPage;
