import { useState } from 'react';
import cuid from 'cuid';
import { FormControlLabel, Tooltip } from '@mui/material';

import ToggleSwitch from '../ToggleSwitch';

interface Props<T> {
  name: keyof T;
  label: string;
  defaultValue: boolean;
  value: boolean;
  tooltip?: string;
  onChange: (name: keyof T, value: boolean) => void;
}

const CheckboxParameter = <T,>({
  name,
  label,
  defaultValue,
  value,
  tooltip,
  onChange,
}: Props<T>) => {
  const [id] = useState(cuid());

  const onValueChange = () => {
    onChange(name, !value);
  };

  const v = value ?? defaultValue;

  return (
    <div className="parameter">
      <div className="inner">
        <Tooltip title={tooltip || label} arrow placement="bottom-start">
          <FormControlLabel
            control={
              <ToggleSwitch
                id={`input-${id}`}
                checked={v}
                onChange={onValueChange}
              />
            }
            label={label}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default CheckboxParameter;
