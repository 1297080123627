import React from 'react';
import { DEFAULT_ERROR_MESSAGE } from '../consts';

const UnknownError = () => (
  <div style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
    {DEFAULT_ERROR_MESSAGE}
  </div>
);

export default UnknownError;
