import { CircularProgress, IconButton, Typography } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { PauseCircle as PauseCircleIcon } from '@mui/icons-material';

const CustomToolbar = ({
  selectedRowCount,
  isDisabled,
  onPauseClick,
  isProcessingPause,
}: {
  selectedRowCount: number;
  isDisabled: boolean;
  onPauseClick: () => void;
  isProcessingPause: boolean;
}) => {
  return (
    <GridToolbarContainer>
      <Typography variant="subtitle1" component="div">
        {selectedRowCount} {' selected'}
      </Typography>
      <IconButton
        onClick={() => onPauseClick()}
        disabled={isDisabled || isProcessingPause}
      >
        {isProcessingPause ? (
          <CircularProgress
            style={{
              width: '1em',
              height: '1em',
            }}
            color="inherit"
          />
        ) : (
          <PauseCircleIcon />
        )}
      </IconButton>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
