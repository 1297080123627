import { createContext } from 'react';

export interface AuthContextInterface {
  isInitializing: boolean;
  accessToken: string;
  firebaseToken?: string;
  permissions?: string[];
  isReady: boolean;
  setIsReady: (value: boolean) => void;
}

const context = createContext<AuthContextInterface>({} as AuthContextInterface);

export default context;
