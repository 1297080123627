import React, { useCallback, useEffect, useRef } from 'react';
import { AreaWidthChange, Direction, RowHeightChange } from '../types';

interface Props {
  containerIndex?: number;
  containerSize?: string;
  at?: Direction;
  onChange?: (change: RowHeightChange | AreaWidthChange) => void;
  onUpdate?: (change: RowHeightChange | AreaWidthChange) => void;
}

interface Context {
  startPosition: number;
  startSize: string;
}

const context: Context = {
  startPosition: 0,
  startSize: '0px',
};

const Splitter = ({
  containerIndex,
  containerSize,
  onChange,
  onUpdate,
  at,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const orientation =
    at === 'top' || at === 'bottom' ? 'horizontal' : 'vertical';

  // Called while dragging
  const onPointerMove = useCallback(
    (e: PointerEvent) => {
      const elem = e.target;

      if (
        elem instanceof HTMLElement &&
        containerIndex !== undefined &&
        ref.current
      ) {
        const { clientX: x, clientY: y } = e;
        const delta =
          orientation === 'vertical'
            ? x - context.startPosition
            : y - context.startPosition;

        if (onChange) onChange({ index: containerIndex, delta });
      }
    },
    [containerIndex, onChange, orientation]
  );

  // Called when the dragging is completed
  const onPointerUp = useCallback(
    (e: PointerEvent) => {
      const elem = e.target;

      if (
        elem instanceof HTMLElement &&
        containerIndex !== undefined &&
        ref.current
      ) {
        const { clientX: x, clientY: y } = e;
        const delta =
          orientation === 'vertical'
            ? x - context.startPosition
            : y - context.startPosition;

        elem.classList.remove('moving');

        window.removeEventListener('pointermove', onPointerMove);
        window.removeEventListener('pointerup', onPointerUp);

        if (onUpdate) onUpdate({ index: containerIndex, delta });
      }
    },
    [orientation, containerIndex, onUpdate, onPointerMove]
  );

  // Called when the pointer is down for dragging
  const onPointerDown = useCallback(
    (e: PointerEvent) => {
      const elem = e.target;

      if (elem instanceof HTMLElement) {
        elem.classList.add('moving');

        window.addEventListener('pointermove', onPointerMove);
        window.addEventListener('pointerup', onPointerUp);

        context.startPosition =
          orientation === 'vertical' ? e.clientX : e.clientY;

        context.startSize = containerSize ?? '0px';
      }
    },
    [containerSize, onPointerMove, onPointerUp, orientation]
  );

  // Add pointerdown event handler on mount
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('pointerdown', onPointerDown);
    }
  }, [onPointerDown]);

  return <div ref={ref} className={`splitter ${orientation} ${at}`}></div>;
};

export default Splitter;
