import styled from '@emotion/styled';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  DataGrid,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRowsProp,
} from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ConfigTable, ConfigData } from '../../menu/finetune/ConfigSection';
import { useCallback, useEffect, useState } from 'react';

const StyledWrapper = styled('div')({
  '& .MuiAccordionSummary-root': {
    backgroundColor: '#bfd3dc',
    color: 'white',
    fontSize: '0.875rem',
    borderRadius: '4px',

    '& .MuiSvgIcon-root': {
      fill: 'white',
    },
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid #f0f0f0`,
    backgroundColor: '#f4f3ce',
    color: '#7b8082',
    maxHeight: '30px !important',
    minHeight: '30px !important',
    fontSize: '0.875rem',

    '& .MuiCheckbox-root.Mui-checked': {
      color: '#297578',
    },
  },
  '& .MuiDataGrid-cell': {
    minHeight: '22px !important',

    '&:focus, &:focus-within': {
      outline: 'none !important',
    },
    '&.MuiDataGrid-cell--editable.MuiDataGrid-cell--editing': {
      outline: 'solid #bfd3dc 2px !important',
      borderRadius: '4px',
      minHeight: '22px !important',
      padding: '0 !important',
    },
  },

  '& .MuiAccordionDetails-root h3': {
    color: '#9b9797',
    fontWeight: 600,
    paddingBottom: '5px',
  },
});

type CustomTableAccordionProps = {
  configSections: Record<string, ConfigData>;
  columns: GridColDef[];
  createRows: (data: ConfigData) => GridRowsProp;
  onConfigDataUpdate: (section: string, updatedData: ConfigTable) => void;
};

const CustomTableAccordion = ({
  configSections,
  columns,
  createRows,
  onConfigDataUpdate,
}: CustomTableAccordionProps) => {
  const { formatMessage } = useIntl();
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const handleProcessRowUpdateError = () => {
    toast.error(
      formatMessage({
        id: 'tuner.msg.error-config-update',
        defaultMessage: 'Failed to update the configuration data.',
      })
    );
  };

  const handleProcessRowUpdate = (updatedRow: any, section: string) => {
    onConfigDataUpdate(section, updatedRow);
    return updatedRow;
  };

  // MUI guide code for single click editing
  const revertRowModes = (prevModel: any, rowId: any): any => {
    const newRowModes: GridCellModesModel = {};

    // Revert the mode of the other cells from other rows
    Object.keys(prevModel).forEach((id) => {
      newRowModes[id] = Object.keys(prevModel[id]).reduce(
        (acc2, field) => ({
          ...acc2,
          [field]: { mode: GridCellModes.View },
        }),
        {}
      );
    });

    // Revert the mode of other cells in the same row
    newRowModes[rowId] = Object.keys(prevModel[rowId] || {}).reduce(
      (acc, field) => ({
        ...acc,
        [field]: { mode: GridCellModes.View },
      }),
      {}
    );

    return newRowModes;
  };

  const handleCellModesModelChange = useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    []
  );

  const handleCellClick = useCallback(
    (params: GridCellParams) => {
      if (!params.isEditable) {
        setCellModesModel(revertRowModes(cellModesModel, params.id));
        return;
      }

      setCellModesModel((prevModel) => ({
        ...revertRowModes(prevModel, params.id),
        [params.id]: {
          ...revertRowModes(prevModel[params.id] || {}, params.id),
          [params.field]: { mode: GridCellModes.Edit },
        },
      }));
    },
    [cellModesModel]
  );

  // when clicks outside the DataGrid change cell to view mode
  const handleGlobalClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Element;
      const isClickInsideDataGrid = target.closest('.MuiDataGrid-root');
      if (!isClickInsideDataGrid) {
        setCellModesModel(
          revertRowModes(cellModesModel, Object.keys(cellModesModel)[0])
        );
      }
    },
    [cellModesModel]
  );

  useEffect(() => {
    document.addEventListener('click', handleGlobalClick);
    return () => {
      document.removeEventListener('click', handleGlobalClick);
    };
  }, [handleGlobalClick]);

  return (
    <StyledWrapper>
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Configuration</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {Object.entries(configSections).map(([section, data]) => (
            <div key={section}>
              <Typography variant="h3">[ {section} ]</Typography>
              <DataGrid
                autoHeight
                editMode="row"
                getRowHeight={() => 'auto'}
                rows={createRows(data)}
                columns={columns}
                processRowUpdate={(updatedRow) =>
                  handleProcessRowUpdate(updatedRow, section)
                }
                onProcessRowUpdateError={handleProcessRowUpdateError}
                cellModesModel={cellModesModel}
                onCellModesModelChange={handleCellModesModelChange}
                onCellClick={handleCellClick}
              />
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </StyledWrapper>
  );
};

export default CustomTableAccordion;
