import { createContext, PropsWithChildren, useState } from 'react';
import { IntlProvider } from 'react-intl';

import enMessages from './lang/en.json';
import koMessages from './lang/ko.json';

const messageMap: Record<string, any> = {
  en: enMessages,
  ko: koMessages,
};

let locale = 'en-US';

try {
  locale =
    (window.localStorage &&
      window.localStorage.getItem('supertone.studio.language')) ??
    navigator.language;
} catch (e) {}

const lang = locale.split(/[-_]/)[0];

const defaultValues = {
  supportedLanguages: [
    { language: 'en', label: 'English' },
    { language: 'ko', label: '한국어' },
  ],
  language: 'en',
  changeLanguage: (lang: string) => {},
};

export const LanguageContext = createContext(defaultValues);

const LanguageProvider = ({ children }: PropsWithChildren<{}>) => {
  const [language, setLanguage] = useState(lang);
  const [messages, setMessages] = useState(messageMap[lang]);

  const changeLanguage = (lang: string) => {
    try {
      if (window.localStorage)
        localStorage.setItem('supertone.studio.language', lang);
    } catch (e) {}

    setLanguage(lang);
    setMessages(messageMap[lang]);
  };

  return (
    <LanguageContext.Provider
      value={{
        supportedLanguages: defaultValues.supportedLanguages,
        language,
        changeLanguage,
      }}
    >
      <IntlProvider locale={language} messages={messages}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
