import { base64DecToArr } from './string';

export interface Recorder {
  start: () => void;
  stop: () => Promise<Blob>;
}

const audioContext = new AudioContext();

// Export audio context for other modules to use.
export const getAudioContext = () => audioContext;

export const recordAudio = () =>
  new Promise<Recorder>(async (resolve) => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    const audioChunks: BlobPart[] = [];

    mediaRecorder.addEventListener('dataavailable', (event) => {
      audioChunks.push(event.data);
    });

    const start = () => mediaRecorder.start();

    const stop = () =>
      new Promise<Blob>((resolve) => {
        mediaRecorder.addEventListener('stop', () => {
          resolve(new Blob(audioChunks));
        });

        mediaRecorder.stop();
      });

    resolve({ start, stop });
  });

// Convert AudioBuffer to a Blob using WAV representation.
// https://russellgood.com/how-to-convert-audiobuffer-to-audio-file/
export const convertAudioBufferToWavBlob = (abuffer: AudioBuffer) => {
  var numOfChan = abuffer.numberOfChannels,
    length = abuffer.length * numOfChan * 2 + 44,
    buffer = new ArrayBuffer(length),
    view = new DataView(buffer),
    channels = [],
    i,
    sample,
    offset = 0,
    pos = 0;

  // write WAVE header
  setUint32(0x46464952); // "RIFF"
  setUint32(length - 8); // file length - 8
  setUint32(0x45564157); // "WAVE"

  setUint32(0x20746d66); // "fmt " chunk
  setUint32(16); // length = 16
  setUint16(1); // PCM (uncompressed)
  setUint16(numOfChan);
  setUint32(abuffer.sampleRate);
  setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
  setUint16(numOfChan * 2); // block-align
  setUint16(16); // 16-bit (hardcoded in this demo)

  setUint32(0x61746164); // "data" - chunk
  setUint32(length - pos - 4); // chunk length

  // write interleaved data
  for (i = 0; i < abuffer.numberOfChannels; i++)
    channels.push(abuffer.getChannelData(i));

  while (pos < length) {
    for (i = 0; i < numOfChan; i++) {
      // interleave channels
      sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
      view.setInt16(pos, sample, true); // write 16-bit sample
      pos += 2;
    }
    offset++; // next source sample
  }

  // create Blob
  return new Blob([buffer], { type: 'audio/wav' });

  function setUint16(data: number) {
    view.setUint16(pos, data, true);
    pos += 2;
  }

  function setUint32(data: number) {
    view.setUint32(pos, data, true);
    pos += 4;
  }
};

export const convertWavStringToBlob = (data: string) => {
  const arr = base64DecToArr(data);
  const blob = new Blob([arr], { type: 'audio/wav' });

  return blob;
};

// Convert stereo AudioBuffer to mono.
export const convertStereoToMono = (
  audioContext: AudioContext,
  audioBuffer: AudioBuffer
) => {
  // If the audio is already mono, return the original buffer.
  if (audioBuffer.numberOfChannels === 1) return audioBuffer;

  const channelData = audioBuffer.getChannelData(0);
  const newAudioBuffer = audioContext.createBuffer(
    1,
    channelData.length,
    audioBuffer.sampleRate
  );

  const leftChannelData = audioBuffer.getChannelData(0);
  const rightChannelData = audioBuffer.getChannelData(1);
  const newChannelData = new Float32Array(channelData.length);

  // Average the two channels into one.
  for (let i = 0; i < channelData.length; i++) {
    newChannelData[i] = (leftChannelData[i] + rightChannelData[i]) / 2;
  }

  newAudioBuffer.copyToChannel(newChannelData, 0);

  return newAudioBuffer;
};

// Convert audio blob to AudioBuffer.
export const convertBlobToAudioBuffer = (
  audioContext: AudioContext,
  blob: Blob
) => {
  return new Promise<AudioBuffer>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target?.result as ArrayBuffer;

      audioContext
        .decodeAudioData(arrayBuffer)
        .then((audioBuffer) => {
          resolve(audioBuffer);
        })
        .catch((e) => {
          reject(e);
        });
    };

    reader.readAsArrayBuffer(blob);
  });
};

export const convertToMel = (t: number) => 2595 * Math.log10(1 + t / 700);

// Get audio duration from a file.
export const getAudioDurationFromFile = (file: Blob) => {
  return new Promise<number>((resolve) => {
    const audio = document.createElement('audio');

    audio.addEventListener('loadedmetadata', () => {
      resolve(audio.duration);
    });

    audio.src = URL.createObjectURL(file);
  });
};
