import { PropsWithChildren } from 'react';
import Dropzone, { Accept, FileRejection, FileWithPath } from 'react-dropzone';
import { useIntl } from 'react-intl';

interface Props {
  accept?: Accept;
  maxSize?: number;
  successCallback: (filed: FileWithPath[]) => void;
  invalidFileTypeErrorMessage?: string;
}

const toMB = (size: number) => {
  return size / 1000000;
};

const DropzoneContainer = ({
  children,
  accept,
  maxSize,
  successCallback,
  invalidFileTypeErrorMessage,
}: PropsWithChildren<Props>) => {
  const { formatMessage } = useIntl();

  // 기본 에러 메시지는 오디오 관련 메시지로 설정
  if (!invalidFileTypeErrorMessage) {
    invalidFileTypeErrorMessage = formatMessage({
      id: 'err.invalid-audio-file-type',
      defaultMessage:
        'Supertone Studio는 오디오 파일만 지원하고 있습니다. WAV, MP3 파일로 다시 업로드해주세요.',
    });
  }

  const onDrop = (
    acceptedFiles: FileWithPath[],
    fileRejections: FileRejection[]
  ) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (maxSize && err.code === 'file-too-large') {
          alert(
            formatMessage(
              {
                id: 'err.file-too-large',
                defaultMessage:
                  '첨부하려는 파일의 크기가 서버에서 허용하는 크기보다 큽니다. 파일을 업로드할 경우 파일 크기를 {size}MB 미만으로 업로드해주세요.',
              },
              {
                size: toMB(maxSize),
              }
            )
          );
        }
        if (err.code === 'file-invalid-type') {
          alert(invalidFileTypeErrorMessage);
        }
      });
    });
    successCallback(acceptedFiles);
  };
  return (
    <Dropzone onDrop={onDrop} accept={accept} maxSize={maxSize}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className="dropzone">
          <input {...getInputProps()} />
          {children}
        </div>
      )}
    </Dropzone>
  );
};

export default DropzoneContainer;
