import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import cn from 'classnames';

const ActiveLink = ({ children, to, ...props }: LinkProps) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });

  return (
    <div>
      <Link className={cn(match && 'active')} to={to} {...props}>
        {children}
      </Link>
    </div>
  );
};

export default ActiveLink;
