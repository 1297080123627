import axios, { AxiosResponse } from 'axios';
import { AudioData } from '../common/types';
import { addSuffixToFileName } from '../util/string';

const defaultBaseURL = process.env.REACT_APP_API_HOST;

export const sendPostRequest = async <T = any, U = any>(
  url: string,
  data: T,
  headers: Record<string, string> = {},
  config: Record<string, string> = {},
  controller?: AbortController
) => {
  const obj = {
    headers: { ...headers },
    ...config,
    signal: controller?.signal,
  };

  return await axios.post<T, AxiosResponse<U>>(defaultBaseURL + url, data, obj);
};

export const removeEmptyKeys = (obj: Record<string, any>) =>
  Object.keys(obj).reduce<Record<string, any>>((acc, k) => {
    if (obj[k] !== undefined) acc[k] = obj[k];
    return acc;
  }, {});

export const formatFormData = (data: Record<string, any>) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      data[key].forEach((item: any) => {
        formData.append(key, item);
      });
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
};

// Error report api 데이터 형식에 맞게 변환
export const formatReportData = (data: Record<string, any>) => {
  const { api, model_type, src, tgt, tgt1, tgt2, user, problem, ...rest } =
    data;

  const files: File[] = [];
  const fileKeys = ['src', 'tgt', 'tgt1', 'tgt2'];

  fileKeys.forEach((key) => {
    const filesData = data[key];
    if (filesData?.length) {
      filesData.forEach(({ file }: AudioData) => {
        files.push(
          new File([file], addSuffixToFileName(file.name, `_${key}`), {
            type: file.type,
          })
        );
      });
    }
  });

  const parameters = JSON.stringify(rest);
  const formData = formatFormData({
    api,
    model_type,
    user,
    problem,
    parameters,
    files,
  });

  return formData;
};

export const sendReport = (data: Record<string, any>) =>
  new Promise((resolve, reject) => {
    try {
      const r = sendPostRequest(
        process.env.REACT_APP_API_PATH_REPORT!,
        data,
        {
          'Content-Type': 'multipart/form-data',
        },
        {
          baseURL: process.env.REACT_APP_API_HOST as string,
        }
      );
      resolve(r);
    } catch (e) {
      reject(e);
    }
  });

let abortControllers: AbortController[] = [];

export const sendBlobRequest = async (
  url: string,
  data: Record<string, any>
) => {
  const controller = new AbortController();
  abortControllers.push(controller);
  return await sendPostRequest(
    url,
    data,
    {
      'Content-Type': 'multipart/form-data',
    },
    {
      responseType: 'blob',
    },
    controller
  );
};

export const abortBlobRequestAll = () => {
  abortControllers?.forEach((c) => c.abort());
};

export const resetControllers = () => {
  abortControllers = [];
};
